export const COURSE_STATUS = {
  DRAFT: "draft",
  PENDING: "pending",
  PUBLISHED: "published",
  PRIVATE: "private",
  DELETED: "deleted"
};

export const COURSE_TYPE = {
  VAI_TRO: "Vai trò",
  LUYEN_THI: "Luyện thi",
  TECH_STACK: "Tech stack"
};

export const COURSE_VERSION = {
  FREE: "free",
  PRO: "pro"
};

export const LEVEL_VERSION = {
  FREE: "free",
  PRO: "pro"
};

export const COURSE_DIFFICULTY = {
  EASY: "easy",
  MEDIUM: "medium",
  HARD: "hard",
  VERY_HARD: "very_hard",
  INSANE: "insane"
};

export const COURSE_CATEGORY = {
  DATABASE: "database",
  POWER_BI: "power_bi",
  PYTHON: "python"
};
