import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, HStack, Icon, IconButton, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { isEmpty, isEqual, set } from "lodash";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { BiEditAlt } from "react-icons/bi";
import { FaPlus } from "react-icons/fa6";
import { IoChevronBack } from "react-icons/io5";
import { MdCheckCircle } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppToast from "../../../../components/AppToast";
import CustomConfirmIconButton from "../../../../components/CustomConfirmIconButton";
import CustomInput from "../../../../components/CustomInput";
import { CustomInputNumber } from "../../../../components/CustomNumberInput";
import CustomUploadButton from "../../../../components/CustomUploadButton";
import { HSeparator } from "../../../../components/Separator";
import roadmapService from "../../../../services/roadmapService";
import { getErrorMessage } from "../../../../utils/helpers";
import EditNameModal from "../../components/EditNameModal";
import CourseContext from "../../layout/context/CourseContext";
import AddLessonModal from "./AddLessonModal";
import { LESSON_TYPE, NODE_TYPE } from "./constant";
import EditTypeModal from "./EditTypeModal";
import ListLesson from "./ListLesson";
import ListQuestion from "./ListQuestion";
import UpdateLessonModal from "./UpdateLessonModal";
import { CiNoWaitingSign } from "react-icons/ci";
import UpdateVideoLesson from "./UpdateVideoLesson";
import CustomConfirmButton from "../../../../components/CustomConfirmButton";

type FormType = {
  id?: number;
  name: string;
  subtitle?: string;
  levelId: number;
  type: string;
  icon?: string;
  idx: number;
  exp?: number;
  nut?: number;
};

export default function Node() {
  const { id, levelId, nodeId, lessonId } = useParams();
  const [queries] = useSearchParams();
  const type = queries.get("type");
  const navigate = useNavigate();
  const toast = useToast();
  const { node, setNode, lesson, setLesson, isEditing, setIsEditing } = React.useContext(CourseContext);

  const { isOpen: isOpenNameModal, onOpen: onOpenNameModal, onClose: onCloseNameModal } = useDisclosure();
  const { isOpen: isOpenTypeModal, onOpen: onOpenTypeModal, onClose: onCloseTypeModal } = useDisclosure();
  const {
    isOpen: isOpenAddLessonModal,
    onOpen: onOpenAddLessonModal,
    onClose: onCloseAddLessonModal
  } = useDisclosure();
  const {
    isOpen: isOpenUpdateLessonModal,
    onOpen: onOpenUpdateLessonModal,
    onClose: onCloseUpdateLessonModal
  } = useDisclosure();

  const [currentNode, setCurrentNode] = React.useState<any>(null);
  const [lessonList, setLessonList] = React.useState<any[]>([]);
  const [addLesson, setAddLesson] = React.useState<any>(null);
  const [viewQuestion, setViewQuestion] = React.useState<any>(false);
  const [questionList, setQuestionList] = React.useState<any[]>([]);

  const { control, setValue, reset, watch, handleSubmit: onSubmit } = useForm<FormType>({});

  const initLessons = async () => {
    const { data: { data: list } = { data: {} } } = await roadmapService.getAllLessons(nodeId);
    setLessonList(list.rows);
  };

  const initQuestions = async (lesson?: any) => {
    const { data: { data: list } = { data: {} } } = await roadmapService.getAllQuestions(
      nodeId,
      lesson ? { lessonId: lesson.id } : undefined
    );
    setQuestionList(list.rows);
    if (lesson) {
      setLesson(lesson);
      setViewQuestion(true);
    }
  };

  React.useEffect(() => {
    if (isEmpty(node)) return;
    if (isEqual(node, currentNode)) return;

    reset({ ...node, subtitle: node.subtitle ?? "" });
    setCurrentNode(node);
    setViewQuestion(false);
    setLessonList([]);

    if ([NODE_TYPE.PRACTICE, NODE_TYPE.FINAL_TEST].includes(node.type)) {
      initQuestions();
    } else if (node.type === NODE_TYPE.NORMAL_TEST) {
      initLessons();
    }
  }, [node, nodeId, lessonId]);

  React.useEffect(() => {
    if (isEmpty(lessonId)) {
      setViewQuestion(false);
      return;
    }
    if (isEmpty(lessonList)) return;
    const lesson = lessonList.find((lesson) => lesson.id === Number(lessonId));
    initQuestions(lesson);
  }, [lessonList, lessonId]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataUpdate: any) => {
      return roadmapService.update(nodeId, dataUpdate);
    },
    onSuccess: async (res: any) => {
      const { data: { data: updatedData } = { data: {} } } = res;
      setNode(updatedData);
      setIsEditing(false);

      toast({
        description: "Lưu thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
    },
    onError: (error) => {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  });

  const handleUpload = async (name: string, file: string | string[]) => {
    if (name === "icon") {
      setValue(name, typeof file === "object" ? file[0] : (file as string));
      setIsEditing(true);
      return;
    }
  };

  const save: SubmitHandler<FormType> = async (data) => {
    mutate(data);
  };

  const confirmDelete = async () => {
    const { data: { data: _updated } = { data: {} } } = await roadmapService.delete(nodeId);

    setNode(null);
    navigate(`/course-management/${id}/levels/${levelId}?type=${type}`);
  };

  const handleAddLesson = async (data: any) => {
    const obj = {
      nodeId,
      idx: lessonList.length + 1
    };
    setAddLesson(obj);
    onOpenAddLessonModal();
  };

  const saveAddLesson = async (data: any) => {
    setLessonList((prev) => [...prev, data]);
    onCloseAddLessonModal();
  };

  const saveUpdateLesson = async (data: any) => {
    const updated = lessonList.map((lesson) => {
      if (lesson.id === data.id) {
        return data;
      }
      return lesson;
    });
    setLessonList(updated);
    setLesson(data);
    onCloseUpdateLessonModal();
  };

  if (!node) return null;

  return (
    <Box w="full" px="0px">
      <Flex
        w={{ base: "calc(100% - 314px)", xl: "calc(100% - 464px)" }}
        justify="space-between"
        align="center"
        mb={"20px"}
      >
        <HStack>
          {viewQuestion && (
            <Icon
              as={IoChevronBack}
              cursor={"pointer"}
              userSelect={"none"}
              onClick={() => {
                if (isEditing) {
                  if (
                    confirm(
                      `Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`
                    ) == true
                  ) {
                    return;
                  }
                  setIsEditing(false);
                }
                setLesson(null);
                setViewQuestion(false);
                navigate(`/course-management/${id}/levels/${levelId}/nodes/${nodeId}?type=${type}`);
              }}
            />
          )}
          <HStack>
            <Text fontSize={"3xl"} fontWeight={800} whiteSpace={"nowrap"}>
              {viewQuestion ? lesson?.title : watch("name")}
            </Text>
            <Icon
              as={BiEditAlt}
              cursor={"pointer"}
              onClick={viewQuestion ? onOpenUpdateLessonModal : onOpenNameModal}
            />
          </HStack>
        </HStack>
        {!([NODE_TYPE.PRACTICE, NODE_TYPE.FINAL_TEST].includes(watch("type")) || viewQuestion) && (
          <HStack spacing={2}>
            <CustomConfirmButton
              title={`Xoá node`}
              question={"Are you sure to delete this Node?"}
              leftIcon={<DeleteIcon />}
              color={"black"}
              data={node}
              onConfirm={confirmDelete}
              border={"1px solid rgba(135, 140, 189, 0.3)"}
              h="32px"
              rounded={"20px"}
            />
            {watch("type") === NODE_TYPE.NORMAL_TEST && (
              <IconButton
                aria-label="add node"
                bg="black"
                w="30px"
                minW="0px"
                h="30px"
                minH="0px"
                icon={<Icon as={FaPlus} color={"white"} />}
                onClick={handleAddLesson}
              />
            )}
          </HStack>
        )}
        {[NODE_TYPE.PRACTICE, NODE_TYPE.FINAL_TEST].includes(watch("type")) && (
          <HStack spacing={2}>
            <CustomConfirmButton
              title={`Xoá node`}
              question={"Are you sure to delete this Node?"}
              leftIcon={<DeleteIcon />}
              color={"black"}
              data={node}
              onConfirm={confirmDelete}
              border={"1px solid rgba(135, 140, 189, 0.3)"}
              h="32px"
              rounded={"20px"}
            />
            <IconButton
              aria-label="add question"
              bg="black"
              w="30px"
              minW="0px"
              h="30px"
              minH="0px"
              icon={<Icon as={FaPlus} color={"white"} />}
              onClick={() => {
                if (isEditing) {
                  if (
                    confirm(
                      `Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`
                    ) == true
                  ) {
                    return;
                  }
                  setIsEditing(false);
                }
                navigate(
                  `/course-management/${id}/levels/${levelId}/nodes/${nodeId}${lessonId ? `/lessons/${lessonId}` : ""}/questions/new?type=${type}`
                );
              }}
            />
          </HStack>
        )}
        {viewQuestion && (
          <HStack spacing={2}>
            <CustomConfirmButton
              title={`Xoá bài học`}
              question={"Are you sure to delete this Lesson?"}
              leftIcon={<DeleteIcon />}
              color={"black"}
              data={node}
              onConfirm={confirmDelete}
              border={"1px solid rgba(135, 140, 189, 0.3)"}
              h="32px"
              rounded={"20px"}
            />
            {lesson && lesson.type !== LESSON_TYPE.VIDEO && (
              <IconButton
                aria-label="add question"
                bg="black"
                w="30px"
                minW="0px"
                h="30px"
                minH="0px"
                icon={<Icon as={FaPlus} color={"white"} />}
                onClick={() => {
                  navigate(
                    `/course-management/${id}/levels/${levelId}/nodes/${nodeId}${lessonId ? `/lessons/${lessonId}` : ""}/questions/new?type=${type}`
                  );
                }}
              />
            )}
          </HStack>
        )}
      </Flex>
      <HStack spacing={4} align="flex-start">
        <VStack w="full" spacing={4} align="flex-start">
          {([NODE_TYPE.PRACTICE, NODE_TYPE.FINAL_TEST].includes(watch("type")) ||
            (viewQuestion && lesson && lesson.type !== LESSON_TYPE.VIDEO)) && (
            <ListQuestion questionList={questionList} setQuestionList={setQuestionList} />
          )}
          {watch("type") === NODE_TYPE.NORMAL_TEST && viewQuestion && lesson && lesson.type === LESSON_TYPE.VIDEO && (
            <UpdateVideoLesson setLessonList={setLessonList} />
          )}
          {watch("type") === NODE_TYPE.NORMAL_TEST && !viewQuestion && (
            <ListLesson
              lessonList={lessonList}
              setLessonList={setLessonList}
              initQuestions={(lesson) => {
                if (isEditing) {
                  if (
                    confirm(
                      `Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`
                    ) == true
                  ) {
                    return;
                  }
                  setIsEditing(false);
                }
                navigate(
                  `/course-management/${id}/levels/${levelId}/nodes/${nodeId}/lessons/${lesson.id}?type=${type}`
                );
              }}
            />
          )}
        </VStack>
        {!viewQuestion && (
          <VStack minW={{ base: "300px", xl: "450px" }} spacing={4} align="flex-start">
            <Box w="full" bg="white" rounded={"20px"}>
              <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
                {"Cài đặt Node"}
              </Text>
              <HSeparator mb="20px" />
              <VStack spacing={4} align="flex-start" px="20px">
                <Flex w="full" justify={"space-between"} align={"center"}>
                  <Text w="full" fontSize={"sm"}>
                    {`Loại node: `}
                    <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                      {watch("type")}
                    </Text>
                  </Text>
                  <Text
                    color={"brand.500"}
                    fontSize={"sm"}
                    fontWeight={"bold"}
                    cursor={"pointer"}
                    userSelect={"none"}
                    onClick={onOpenTypeModal}
                  >
                    {"Edit"}
                  </Text>
                </Flex>
                <Text w="full" fontSize={"sm"}>
                  {`Ngày cập nhật: `}
                  <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                    {dayjs(node.updatedAt).format("DD/MM/YYYY HH:mm a")}
                  </Text>
                </Text>
                <Text w="full" fontSize={"sm"} fontWeight={500}>
                  {`Mô tả`}
                </Text>
                <Controller
                  name="subtitle"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <CustomInput
                      isMultipleLines={true}
                      lines={2}
                      value={value}
                      error={error}
                      onTextChange={(value) => {
                        onChange(value);
                        setIsEditing(true);
                      }}
                    />
                  )}
                />
                {[NODE_TYPE.PRACTICE, NODE_TYPE.FINAL_TEST].includes(watch("type")) && (
                  <>
                    <Text w="full" fontSize={"sm"} fontWeight={500}>
                      {`Item image`}
                    </Text>
                    <CustomUploadButton
                      bg={"secondaryGray.300"}
                      h="100px"
                      label="Add item image"
                      onUploadChange={handleUpload}
                      name={`icon`}
                      src={watch("icon") ?? ""}
                      thumbnail
                      rounded="8px"
                      w="120px"
                      minW="120px"
                    />
                  </>
                )}
              </VStack>
              <HSeparator mt="20px" />
              <Flex w="full" justify={"space-between"} align={"center"} p={"10px"}>
                <HStack spacing={2}>
                  <Icon as={isEditing ? CiNoWaitingSign : MdCheckCircle} color={!isEditing && "green.500"} />
                  <Text fontSize={"sm"} fontWeight={600}>
                    {isEditing ? "Chưa lưu" : "Đã lưu"}
                  </Text>
                </HStack>
                <HStack spacing={2}>
                  <Button isLoading={isLoading} onClick={onSubmit(save)} border={"1px solid rgba(135, 140, 189, 0.3)"}>
                    {"Lưu"}
                  </Button>
                </HStack>
              </Flex>
            </Box>
            {[NODE_TYPE.PRACTICE, NODE_TYPE.FINAL_TEST].includes(watch("type")) && (
              <Box w="full" bg="white" rounded={"20px"} pb={"30px"}>
                <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
                  {"Phần thưởng"}
                </Text>
                <HSeparator mb="20px" />
                <VStack spacing={4} align="flex-start" px="20px">
                  <Text w="full" fontSize={"sm"} fontWeight={500}>
                    {`Kinh nghiệm`}
                  </Text>
                  <Controller
                    name="exp"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <CustomInputNumber
                        value={value ?? 0}
                        error={error}
                        onChange={(value) => {
                          onChange(value);
                          setIsEditing(true);
                        }}
                      />
                    )}
                  />
                  <Text w="full" fontSize={"sm"} fontWeight={500}>
                    {`Hạt dẻ`}
                  </Text>
                  <Controller
                    name="nut"
                    control={control}
                    render={({ field: { onChange, value }, fieldState: { error } }) => (
                      <CustomInputNumber
                        value={value ?? 0}
                        error={error}
                        onChange={(value) => {
                          onChange(value);
                          setIsEditing(true);
                        }}
                      />
                    )}
                  />
                </VStack>
              </Box>
            )}
          </VStack>
        )}
      </HStack>
      {isOpenNameModal && (
        <EditNameModal
          name={watch("name")}
          onUpdate={(value) => {
            setValue("name", value);
            setIsEditing(true);
            onCloseNameModal();
          }}
          onClose={onCloseNameModal}
        />
      )}
      {isOpenTypeModal && (
        <EditTypeModal
          type={watch("type")}
          onUpdate={(value) => {
            if (!isEmpty(lessonList) && [NODE_TYPE.PRACTICE, NODE_TYPE.FINAL_TEST].includes(value)) {
              toast({
                position: "top-right",
                render: ({ onClose }) => (
                  <AppToast
                    status={"error"}
                    subtitle={"Node đã có bài học. Không thể đổi loại luyện tập hoặc bài thi!"}
                    onClose={onClose}
                  />
                )
              });
              return;
            }
            setValue("type", value);
            setIsEditing(true);
            onCloseTypeModal();
          }}
          onClose={onCloseTypeModal}
        />
      )}
      {isOpenAddLessonModal && (
        <AddLessonModal data={addLesson} onSave={saveAddLesson} onClose={onCloseAddLessonModal} />
      )}
      {isOpenUpdateLessonModal && (
        <UpdateLessonModal data={{ ...lesson, nodeId }} onSave={saveUpdateLesson} onClose={onCloseUpdateLessonModal} />
      )}
    </Box>
  );
}
