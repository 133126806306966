import { createContext, Dispatch, SetStateAction } from "react";

// Define the context type
type ContextType = {
  course: any;
  setCourse: Dispatch<SetStateAction<any>>;
  level: any;
  setLevel: Dispatch<SetStateAction<any>>;
  node: any;
  setNode: Dispatch<SetStateAction<any>>;
  lesson: any;
  setLesson: Dispatch<SetStateAction<any>>;
  question: any;
  setQuestion: Dispatch<SetStateAction<any>>;
  isEditing: boolean;
  setIsEditing: Dispatch<SetStateAction<boolean>>;
};

// Create the context
const CourseContext = createContext<ContextType>({
  course: undefined,
  setCourse: () => {},
  level: undefined,
  setLevel: () => {},
  node: undefined,
  setNode: () => {},
  lesson: undefined,
  setLesson: () => {},
  question: undefined,
  setQuestion: () => {},
  isEditing: false,
  setIsEditing: () => {}
});

export default CourseContext;
