import { DragHandleIcon } from "@chakra-ui/icons";
import { Box, HStack, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import roadmapService from "../../../../services/roadmapService";
import { MdOutlineEdit, MdOutlineOndemandVideo } from "react-icons/md";
import { SortableItem } from "../../../../components/SortableItem";
import { LESSON_TYPE } from "./constant";
import { LiaDumbbellSolid } from "react-icons/lia";
import { IoDocumentsOutline } from "react-icons/io5";

function ListLesson({
  lessonList,
  setLessonList,
  initQuestions
}: {
  lessonList: any[];
  setLessonList: any;
  initQuestions: (lesson: any) => void;
}) {
  const navigate = useNavigate();
  const [queries] = useSearchParams();
  const type = queries.get("type");
  const { nodeId } = useParams();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentNode, setCurrentNode] = React.useState<any>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  );

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = lessonList.findIndex((choice) => choice.idx === active.id);
      const newIndex = lessonList.findIndex((choice) => choice.idx === over.id);

      const updatedChoices = arrayMove(lessonList, oldIndex, newIndex).map((choice, index) => ({
        ...choice,
        idx: index + 1
      }));

      try {
        const { data: { data: _updatedData } = { data: {} } } = await roadmapService.arrangeLesson(
          nodeId,
          updatedChoices.map((choice) => {
            return { id: choice.id, idx: choice.idx };
          })
        );

        setLessonList((_prev: any) => updatedChoices);
      } catch (_error) {}
    }
  };

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={lessonList.map((choice) => choice.idx!)} strategy={verticalListSortingStrategy}>
          {lessonList.map((choice) => (
            <SortableItem key={choice.idx} id={choice.idx!}>
              {(attributes, listeners) => (
                <Box
                  w="full"
                  display="flex"
                  alignItems="center"
                  gap={4}
                  userSelect={"none"}
                  bg={"white"}
                  border={"1px solid rgba(135, 140, 189, 0.3)"}
                  rounded={"10px"}
                  p={"10px"}
                  {...attributes}
                  onPointerDown={(e) => e.stopPropagation()}
                >
                  <DragHandleIcon cursor={"grab"} {...listeners} />
                  <HStack w="full" spacing={"6px"}>
                    <Icon
                      as={
                        choice.type === LESSON_TYPE.VIDEO
                          ? MdOutlineOndemandVideo
                          : choice.type === LESSON_TYPE.EXERCISE
                            ? IoDocumentsOutline
                            : LiaDumbbellSolid
                      }
                    />
                    <Text w="full">{choice.title}</Text>
                  </HStack>
                  <Icon as={MdOutlineEdit} cursor={"pointer"} onClick={() => initQuestions(choice)} />
                </Box>
              )}
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
}

export default ListLesson;
