import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Text,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CiNoWaitingSign } from "react-icons/ci";
import { MdCheckCircle } from "react-icons/md";
import { useParams } from "react-router-dom";
import AppToast from "../../../../components/AppToast";
import ContentEditor from "../../../../components/ContentEditor";
import { HSeparator } from "../../../../components/Separator";
import roadmapService from "../../../../services/roadmapService";
import { getErrorMessage } from "../../../../utils/helpers";
import CourseContext from "../../layout/context/CourseContext";

type FormType = {
  description: string;
};

export default function UpdateVideoLesson({ setLessonList }: any) {
  const { id, nodeId, lessonId } = useParams();
  const toast = useToast();
  const { lesson, setLesson, isEditing, setIsEditing } = React.useContext(CourseContext);

  const { control, reset, handleSubmit: onSubmit } = useForm<FormType>({});

  React.useEffect(() => {
    reset(lesson);
  }, [id, lessonId]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataUpdate: any) => {
      return roadmapService.updateLesson(nodeId, lesson.id, { ...dataUpdate });
    },
    onSuccess: async (res: any) => {
      const { data: { data: updatedData } = { data: {} } } = res;
      setIsEditing(false);
      setLesson(updatedData);
      setLessonList((prev: any) =>
        prev.map((item: any) => {
          if (item.id === updatedData.id) {
            return updatedData;
          }
          return item;
        })
      );

      toast({
        description: "Lưu thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
    },
    onError: (error) => {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  });

  const save: SubmitHandler<FormType> = async (data) => {
    mutate(data);
  };
  
  return (
    <Box w="full" px="0px">
      <HStack spacing={6} align={"flex-start"}>
        <VStack w="full" align="flex-start" spacing={1}>
          <Text fontWeight={"bold"}>{"Nội dung bài học"}</Text>

          <Controller
            control={control}
            name="description"
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <FormControl isInvalid={!!error} id="description">
                <ContentEditor
                  type={"html"}
                  content={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                    setIsEditing(true);
                  }}
                />

                {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
              </FormControl>
            )}
          />
        </VStack>

        <VStack minW={{ base: "300px", xl: "450px" }} spacing={4} align="flex-start">
          <Box w="full" bg="white" rounded={"20px"}>
            <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
              {"Thông tin bài học"}
            </Text>
            <HSeparator mb="20px" />
            <VStack spacing={4} align="flex-start" px="20px">
              <Flex w="full" justify={"space-between"} align={"center"}>
                <Text w="full" fontSize={"sm"}>
                  {`Loại bài học: `}
                  <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                    {lesson.type}
                  </Text>
                </Text>
              </Flex>
              <Flex w="full" justify={"space-between"} align={"center"}>
                <Text w="full" fontSize={"sm"}>
                  {`Phiên bản: `}
                  <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                    {lesson.versionPlan}
                  </Text>
                </Text>
              </Flex>
              <Text w="full" fontSize={"sm"}>
                {`Ngày cập nhật: `}
                <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                  {lesson.updatedAt ? dayjs(lesson.updatedAt).format("DD/MM/YYYY HH:mm a") : "--"}
                </Text>
              </Text>
            </VStack>
            <HSeparator mt="20px" />
            <Flex w="full" justify={"space-between"} align={"center"} p={"10px"}>
              <HStack spacing={2}>
                <Icon as={isEditing ? CiNoWaitingSign : MdCheckCircle} color={!isEditing && "green.500"} />
                <Text fontSize={"sm"} fontWeight={600}>
                  {isEditing ? "Chưa lưu" : "Đã lưu"}
                </Text>
              </HStack>
              <HStack spacing={2}>
                <Button isLoading={isLoading} onClick={onSubmit(save)} border={"1px solid rgba(135, 140, 189, 0.3)"}>
                  {"Lưu"}
                </Button>
              </HStack>
            </Flex>
          </Box>
        </VStack>
      </HStack>
    </Box>
  );
}
