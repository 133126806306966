import { Box, Button, FlexProps, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import AppToast from "../../../../components/AppToast";
import datasetService from "../../../../services/datasetService";

interface UploadButtonProps extends FlexProps {
  datasetId: number;
  onUploadChange: (uploaded: any) => void;
}

export default function UploadDatatableButton({
  align,
  bg,
  children,
  direction,
  h,
  justify,
  position,
  w,
  datasetId,
  onUploadChange,
  ...others
}: UploadButtonProps) {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const formData = new FormData();
    if (files) {
      for (const selectedFile of files) {
        if (selectedFile.size > 5 * 1024 * 1024) {
          toast({
            position: "top-right",
            render: ({ onClose }) => (
              <AppToast status={"warning"} subtitle={"File size must be less than 5MB."} onClose={onClose} />
            )
          });
          return;
        }

        if (
          ![
            "text/csv",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ].includes(selectedFile.type)
        ) {
          toast({
            position: "top-right",
            render: ({ onClose }) => (
              <AppToast status={"warning"} subtitle={"File type must be csv, xls or xlsx."} onClose={onClose} />
            )
          });
          return;
        }
        formData.append("files", selectedFile);
      }
      setLoading(true);

      datasetService
        .upload(datasetId, formData)
        .then((res: any) => {
          const { data: { data: uploaded } = { data: {} } } = res;
          onUploadChange(uploaded);
          setLoading(false);
          toast({
            position: "top-right",
            render: ({ onClose }) => <AppToast status={"success"} subtitle={"File uploaded!"} onClose={onClose} />
          });
        })
        .catch((err: any) => {
          toast({
            position: "top-right",
            render: ({ onClose }) => (
              <AppToast
                status={"error"}
                subtitle={err?.response?.data?.message ?? "Upload failed!"}
                onClose={onClose}
              />
            )
          });
        });
    }
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Box cursor="pointer" onClick={handleImageClick}>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        style={{ display: "none" }}
        accept=".csv, .xls, .xlsx" // Chỉ chấp nhận các loại tệp này
        multiple
      />
      <Button border={"1px solid rgba(135, 140, 189, 0.3)"} h="32px" rounded={"20px"}>
        {"Tải lên csv, xls, xlsx"}
      </Button>
    </Box>
  );
}
