import { BoxProps, Center, Icon, useDisclosure } from "@chakra-ui/react";
import CustomConfirmAlert from "./CustomConfirmAlert";

interface ButtonProps extends BoxProps {
  title: string;
  question: string;
  icon: any;
  color: string;
  data?: any;
  onConfirm: () => void;
}

export default function CustomConfirmIconButton({
  title,
  question,
  icon,
  color,
  data,
  onConfirm,
  ...others
}: ButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Center {...others}>
      <Icon as={icon} color={color} w="15px" h="15px" cursor={"pointer"} onClick={onOpen} />
      {isOpen && (
        <CustomConfirmAlert
          title={title}
          question={question}
          cancelText={"Cancel"}
          confirmText={"Confirm"}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      )}
    </Center>
  );
}
