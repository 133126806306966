import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Text,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CiNoWaitingSign } from "react-icons/ci";
import { MdCheckCircle } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppToast from "../../../components/AppToast";
import ContentEditor from "../../../components/ContentEditor";
import CustomConfirmIconButton from "../../../components/CustomConfirmIconButton";
import CustomUploadButton from "../../../components/CustomUploadButton";
import { HSeparator } from "../../../components/Separator";
import courseService from "../../../services/courseService";
import { getErrorMessage } from "../../../utils/helpers";
import EditVersionPlanModal from "../components/EditVersionPlanModal";
import CourseContext from "../layout/context/CourseContext";
import CustomConfirmButton from "../../../components/CustomConfirmButton";

type FormType = {
  id?: number;
  name: string;
  idx: number;
  versionPlan: string;
  thumbnail?: string;
  header?: string;
  description?: string;
};

export default function Level() {
  const { id, levelId } = useParams();
  const [queries] = useSearchParams();
  const type = queries.get("type");
  const navigate = useNavigate();
  const toast = useToast();
  const { setCourse, level, setLevel, isEditing, setIsEditing } = React.useContext(CourseContext);

  const { isOpen: isOpenPlanModal, onOpen: onOpenPlanModal, onClose: onClosePlanModal } = useDisclosure();

  const { control, setValue, reset, watch, handleSubmit: onSubmit } = useForm<FormType>({});

  React.useEffect(() => {
    reset(level);
  }, [level]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataUpdate: any) => {
      return courseService.updateLevel(id, levelId, dataUpdate);
    },
    onSuccess: async (res: any) => {
      const { data: { data: updatedData } = { data: {} } } = res;
      setCourse(updatedData);
      const updatedLevel = updatedData?.levels?.find((l: any) => l.id == levelId);
      setLevel(updatedLevel);
      setIsEditing(false);

      toast({
        description: "Lưu thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
    },
    onError: (error) => {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  });

  const handleUpload = async (name: string, file: string | string[]) => {
    if (name === "thumbnail" || name === "header") {
      setValue(name, typeof file === "object" ? file[0] : (file as string));
      setIsEditing(true);
      return;
    }
  };

  const save: SubmitHandler<FormType> = async (data) => {
    mutate(data);
  };

  const confirmDelete = async () => {
    const { data: { data: updated } = { data: {} } } = await courseService.deleteLevel(id, levelId);
    setCourse(updated);
    setLevel(null);
    navigate(`/course-management/${id}?type=${type}`);
  };

  if (!level) return null;

  return (
    <Box w="full" px="0px">
      <Flex
        w={{ base: "calc(100% - 314px)", xl: "calc(100% - 464px)" }}
        justify="space-between"
        align="center"
        mb={"20px"}
      >
        <Text fontSize={"3xl"} fontWeight={800}>
          {watch("name")}
        </Text>
        <CustomConfirmButton
          title={`Xoá cấp độ`}
          question={"Are you sure to delete this Level?"}
          leftIcon={<DeleteIcon />}
          color={"black"}
          data={level}
          onConfirm={confirmDelete}
          border={"1px solid rgba(135, 140, 189, 0.3)"}
          h="32px"
          rounded={"20px"}
        />
      </Flex>
      <HStack spacing={4} align="flex-start">
        <VStack w="full" spacing={4} align="flex-start">
          <VStack w="full" spacing={2} align="flex-start">
            <Text fontSize={"lg"} fontWeight={500}>
              Mô tả cấp độ
            </Text>

            <Controller
              control={control}
              name="description"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <FormControl isInvalid={!!error} id="tags">
                  <ContentEditor
                    type={"html"}
                    content={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      setIsEditing(true);
                    }}
                  />

                  {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                </FormControl>
              )}
            />
          </VStack>
        </VStack>
        <VStack minW={{ base: "300px", xl: "450px" }} spacing={4} align="flex-start">
          <Box w="full" bg="white" rounded={"20px"}>
            <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
              {"Cài đặt cấp độ"}
            </Text>
            <HSeparator mb="20px" />
            <VStack spacing={4} align="flex-start" px="20px">
              <Flex w="full" justify={"space-between"} align={"center"}>
                <Text w="full" fontSize={"sm"}>
                  {`Phiên bản: `}
                  <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                    {watch("versionPlan")}
                  </Text>
                </Text>
                <Text
                  color={"brand.500"}
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  cursor={"pointer"}
                  userSelect={"none"}
                  onClick={onOpenPlanModal}
                >
                  {"Edit"}
                </Text>
              </Flex>
              <Text w="full" fontSize={"sm"}>
                {`Ngày cập nhật: `}
                <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                  {dayjs(level.updatedAt).format("DD/MM/YYYY HH:mm a")}
                </Text>
              </Text>
              <Text w="full" fontSize={"sm"} fontWeight={500}>
                {`Feature image`}
              </Text>
              <CustomUploadButton
                bg={"secondaryGray.300"}
                h="100px"
                label="Add feature image"
                onUploadChange={handleUpload}
                name={`header`}
                src={watch("header") ?? ""}
                thumbnail
                rounded="8px"
                w="120px"
                minW="120px"
              />
              <Text w="full" fontSize={"sm"} fontWeight={500}>
                {`Thumbnail image`}
              </Text>
              <CustomUploadButton
                bg={"secondaryGray.300"}
                h="100px"
                label="Add thumbnail image"
                onUploadChange={handleUpload}
                name={`thumbnail`}
                src={watch("thumbnail") ?? ""}
                thumbnail
                rounded="8px"
                w="120px"
                minW="120px"
              />
            </VStack>
            <HSeparator mt="20px" />
            <Flex w="full" justify={"space-between"} align={"center"} p={"10px"}>
              <HStack spacing={2}>
                <Icon as={isEditing ? CiNoWaitingSign : MdCheckCircle} color={!isEditing && "green.500"} />
                <Text fontSize={"sm"} fontWeight={600}>
                  {isEditing ? "Chưa lưu" : "Đã lưu"}
                </Text>
              </HStack>
              <HStack spacing={2}>
                <Button isLoading={isLoading} onClick={onSubmit(save)} border={"1px solid rgba(135, 140, 189, 0.3)"}>
                  {"Lưu"}
                </Button>
              </HStack>
            </Flex>
          </Box>
        </VStack>
      </HStack>
      {isOpenPlanModal && (
        <EditVersionPlanModal
          type={"level"}
          plan={watch("versionPlan")}
          onUpdate={(value) => {
            setValue("versionPlan", value);
            setIsEditing(true);
            onClosePlanModal();
          }}
          onClose={onClosePlanModal}
        />
      )}
    </Box>
  );
}
