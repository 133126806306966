import { Button, ButtonProps, Icon, useDisclosure } from "@chakra-ui/react";
import CustomConfirmAlert from "./CustomConfirmAlert";

interface CustomButtonProps extends ButtonProps {
  title: string;
  question: string;
  data: any;
  onConfirm: () => void;
}

export default function CustomConfirmButton({ title, question, data, onConfirm, ...others }: CustomButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} {...others}>
        {title}
      </Button>
      {isOpen && data && (
        <CustomConfirmAlert
          title={title}
          question={question}
          cancelText={"Cancel"}
          confirmText={"Confirm"}
          onClose={onClose}
          onConfirm={onConfirm}
        />
      )}
    </>
  );
}
