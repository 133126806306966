import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  VStack
} from "@chakra-ui/react";
import { CustomInputNumber } from "../../../../components/CustomNumberInput";
import { useAppSelector } from "../../../../hooks/useAppSelector";
import { selectSpecialities } from "../../../../redux/slice";
import React from "react";
import CourseContext from "../../layout/context/CourseContext";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";

export default function RoadmapQuestionTranscript({
  transtripts,
  onChange
}: {
  transtripts: any;
  onChange: (transcriptId: number, value: number) => void;
}) {
  const specialityList = useAppSelector(selectSpecialities);
  const { course } = React.useContext(CourseContext);

  return (
    <VStack spacing={4} align="flex-start">
      <Accordion allowMultiple w="full">
        {specialityList?.map((speciality: any) => {
          const { specialityIds } = course;
          if (!specialityIds.includes(speciality.id)) return null;

          return (
            <AccordionItem key={speciality.id} bg={"white"}>
              {({ isExpanded }) => (
                <>
                  <AccordionButton >
                    <Box flex="1" textAlign="left">
                      <Heading size="sm">
                        {speciality.name}
                      </Heading>
                    </Box>
                    {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Flex gap={4} flexWrap={"wrap"}>
                      {speciality?.specialityTranscripts?.map((transcript: any) => {
                        const transcriptData = transtripts?.find((item: any) => item.transcriptId === transcript.id);
                        return (
                          <CustomInputNumber
                            key={transcript.id}
                            label={transcript.nameVi}
                            value={transcriptData?.value ?? 0}
                            onChange={(value) => onChange(transcript.id, value)}
                            minW={"300px"}
                            maxW={"calc((100% - 20px) / 3)"}
                          />
                        );
                      })}
                    </Flex>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
      </Accordion>
    </VStack>
  );
}
