import { Box } from "@chakra-ui/react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

type SortableItemProps = {
  id: string;
  children: (attributes: any, listeners: any) => React.ReactNode;
};

export function SortableItem({ id, children }: SortableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Box w="full" ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children(attributes, listeners)}
    </Box>
  );
}
