import { isEmpty } from "lodash";
import dataServiceAxios from "./baseService";
import qs from "query-string";
const { stringify } = qs;

const courseService = {
  create: function (data: any) {
    return dataServiceAxios.post(`core/courses`, data);
  },
  getAll: function (queryParams: { [key: string]: any }) {
    return dataServiceAxios.get(isEmpty(queryParams) ? `core/courses` : `core/courses?${stringify(queryParams)}`);
  },
  getById: function (id: number | string) {
    return dataServiceAxios.get(`core/courses/${id}`);
  },
  // đếm và lấy tất cả câu hỏi có khai báo điểm theo id khóa học và id chuyên ngành
  getAllQuestionById: function (id: number | string, specialityId: number | string) {
    return dataServiceAxios.get(`core/courses/${id}/get-all-questions?specialityId=${specialityId}`);
  },
  getTranscripts: function (id: number | string, specialityId: number | string) {
    return dataServiceAxios.get(`core/courses/${id}/speciality/${specialityId}/get-transcripts`);
  },
  // ---------------------
  update: function (id: number | string, data: any) {
    return dataServiceAxios.patch(`core/courses/${id}`, data);
  },
  delete: function (id: number | string) {
    return dataServiceAxios.delete(`core/courses/${id}`);
  },
  deletePermanently: function (id: number) {
    return dataServiceAxios.delete(`core/courses/${id}?hardDelete=true`);
  },
  publish: function (id: number) {
    return dataServiceAxios.post(`core/courses/${id}/publish`);
  },
  unPublish: function (id: number) {
    return dataServiceAxios.post(`core/courses/${id}/unpublish`);
  },
  approve: function (id: number) {
    return dataServiceAxios.post(`core/courses/${id}/approve`);
  },
  reject: function (id: number) {
    return dataServiceAxios.post(`core/courses/${id}/reject`);
  },
  disapprove: function (id: number) {
    return dataServiceAxios.post(`core/courses/${id}/disapprove`);
  },
  createLevel: function (id: number | string, data: any) {
    return dataServiceAxios.post(`core/courses/${id}`, data);
  },
  getLevel: function (id: number | string, levelId: number | string) {
    return dataServiceAxios.get(`core/courses/${id}/levels/${levelId}`);
  },
  updateLevel: function (id: number | string, levelId: number | string, data: any) {
    return dataServiceAxios.patch(`core/courses/${id}/levels/${levelId}`, data);
  },
  deleteLevel: function (id: number | string, levelId: number | string) {
    return dataServiceAxios.delete(`core/courses/${id}/levels/${levelId}`);
  }
};

export default courseService;
