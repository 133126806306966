import { AddIcon, ArrowForwardIcon, CheckIcon, DeleteIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import React from "react";
import { FaPlus } from "react-icons/fa6";
import Pagination from "../../../../components/Pagination";
import { HSeparator } from "../../../../components/Separator";
import datasetService from "../../../../services/datasetService";
import AddDatasetModal from "./AddDatasetModal";
import { isEmpty, set } from "lodash";
import CustomConfirmIconButton from "../../../../components/CustomConfirmIconButton";
import { PiTrashSimple } from "react-icons/pi";
import { IoChevronBack } from "react-icons/io5";
import UploadDatatableButton from "./UploadDatatableButton";
import { generateUpdateString } from "../../../../utils/helpers";

type RowObj = {
  id: number;
  title: string;
  totalSelects?: number;
};

export default function DatasetOfQuestion({
  sandbox,
  onUpdateSanbox
}: {
  sandbox: Array<{ id: number; datasetId: number; name: string; url: string }>;
  onUpdateSanbox: (data: any) => void;
}) {
  const [datasetSelected, setDatasetSelected] = React.useState<any>();
  const [datasetTables, setDatasetTables] = React.useState<any[]>([]);

  const [search, setSearch] = React.useState<string>("");
  const [list, setList] = React.useState<Array<RowObj>>();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [page, setPage] = React.useState<number>(1);
  const itemsPerPage = 5;
  const [totalItems, setTotalItems] = React.useState<number>(0);

  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();

  const init = async () => {
    const { data: { data: list } = { data: {} } } = await datasetService.getAll({
      page,
      pageSize: itemsPerPage,
      filter: JSON.stringify({
        title: search
      })
    });
    setTotalItems(list.count);

    list.rows = list.rows.map((item: any) => {
      item.totalSelects = sandbox.filter((sandboxItem) => sandboxItem.datasetId === item.id).length;
      return item;
    });

    setList(list.rows);
    !initialized && setInitialized(true);
  };

  const initTables = async (datasetSelected: any) => {
    if (isEmpty(datasetSelected)) return;

    const { data: { data: list } = { data: {} } } = await datasetService.getAllTables(datasetSelected.id);

    list.rows = list.rows.map((item: any) => {
      item.selected = sandbox.some((sandboxItem) => sandboxItem.id === item.id);
      return item;
    });

    setDatasetTables(list.rows);
  };

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (!initialized) return;
    init();
  }, [initialized, page, search]);

  React.useEffect(() => {
    if (!initialized) return;
    initTables(datasetSelected);
  }, [initialized, datasetSelected]);

  React.useEffect(() => {
    if (!initialized) return;
    setList((prev) =>
      prev.map((item) => {
        item.totalSelects = sandbox.filter((sandboxItem) => sandboxItem.datasetId === item.id).length;
        return item;
      })
    );
  }, [initialized, sandbox]);

  const handleSearch = (value: string) => {
    setPage(1);
    setSearch(value);
  };

  const handleAdd = async () => {
    onOpenAddModal();
  };

  const saveAdd = async (data: any) => {
    setList((prev) => [...prev, data]);
    onCloseAddModal();
  };

  const handleChoseTable = (table: any) => {
    const { selected } = table;
    let newSandbox = [...sandbox];
    if (selected) {
      newSandbox = newSandbox.filter((item) => item.id !== table.id);
    } else {
      newSandbox.push(table);
    }
    onUpdateSanbox(newSandbox);
    setDatasetTables((prev) =>
      prev.map((item) => {
        if (item.id === table.id) {
          item.selected = !selected;
        }
        return item;
      })
    );
  };

  const confirmDeleteDataset = async () => {
    try {
      await datasetService.delete(datasetSelected.id);
      setDatasetSelected(null);
      setList((prev) => prev.filter((item) => item.id !== datasetSelected.id));
    } catch (_error) {}
  };

  return (
    <VStack w="full" align="start" spacing={1} px="10px">
      {isEmpty(datasetSelected) ? (
        <>
          <Flex justify={"space-between"} w="full" align="center" py={"6px"}>
            <InputGroup w={"200px"}>
              <InputLeftElement>
                <IconButton
                  aria-label="search"
                  bg="inherit"
                  borderRadius="inherit"
                  _active={{
                    bg: "inherit",
                    transform: "none",
                    borderColor: "transparent"
                  }}
                  _focus={{
                    boxShadow: "none"
                  }}
                  icon={<SearchIcon color={"gray.700"} w="15px" h="15px" />}
                />
              </InputLeftElement>

              <Input
                variant="search"
                pl="40px"
                fontSize="sm"
                bg={"secondaryGray.300"}
                color={"gray.700"}
                fontWeight="500"
                _placeholder={{ color: "gray.400", fontSize: "14px" }}
                borderRadius={"30px"}
                placeholder={"Tìm kiếm..."}
                onChange={(e: any) => handleSearch(e.target.value)}
              />
            </InputGroup>
            <IconButton
              aria-label="add dataset"
              bg="black"
              w="30px"
              minW="0px"
              h="30px"
              minH="0px"
              icon={<Icon as={FaPlus} color={"white"} />}
              onClick={handleAdd}
            />
          </Flex>
          <HSeparator />
          {list &&
            list.map((item: any) => (
              <Box key={item.id} w="full">
                <HStack
                  w="full"
                  justify="space-between"
                  px={"10px"}
                  py={"10px"}
                  cursor={"pointer"}
                  onClick={() => setDatasetSelected(item)}
                >
                  <Text w="full" fontSize="md" fontWeight="500">
                    {item.title}
                  </Text>
                  <HStack>
                    {(item.totalSelects ?? 0) && (
                      <Center bg={"black"} color={"white"} w={"30px"} h={"30px"} rounded={"50%"}>
                        {item.totalSelects}
                      </Center>
                    )}
                    <ArrowForwardIcon />
                  </HStack>
                </HStack>

                <HSeparator />
              </Box>
            ))}
          <Center w="full" py="10px">
            <Pagination
              iconSize={"30px"}
              page={page}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              onPageChange={setPage}
            />
          </Center>
          {isOpenAddModal && <AddDatasetModal onSave={saveAdd} onClose={onCloseAddModal} />}
        </>
      ) : (
        <>
          <Flex justify={"space-between"} w="full" align="center" py={"6px"}>
            <HStack spacing={4}>
              <Icon
                as={IoChevronBack}
                cursor={"pointer"}
                userSelect={"none"}
                onClick={() => {
                  setDatasetSelected(null);
                  setDatasetTables([]);
                }}
              />
              <Text fontSize="md" fontWeight="600">
                {datasetSelected.title}
              </Text>
            </HStack>
            <CustomConfirmIconButton
              title={`Xoá datasource "${datasetSelected.title}"`}
              question={"Bạn có chắc chắn muốn xoá datasource này?"}
              border={"1px solid red"}
              color="red"
              w="30px"
              h="30px"
              rounded="50%"
              icon={PiTrashSimple}
              onConfirm={confirmDeleteDataset}
            />
          </Flex>
          <HSeparator />
          {datasetTables &&
            datasetTables.map((item: any) => (
              <Box key={item.id} w="full">
                <HStack w="full" justify="space-between" px={"10px"} py={"10px"}>
                  <VStack spacing={2}>
                    <Text w="full" fontSize="md" fontWeight="500">
                      {item.name}
                    </Text>
                    <Text w="full" fontSize="sm" fontWeight="400">
                      {`${autoConvertFileSize(item.size)} • Cập nhật ${generateUpdateString(item.updatedAt)} ngày trước`}
                    </Text>
                  </VStack>
                  <Center
                    border={"1px solid"}
                    borderColor={item.selected ? "green.500" : "black"}
                    minW="20px"
                    minH="20px"
                    rounded={"50%"}
                    userSelect={"none"}
                    cursor={"pointer"}
                    onClick={() => handleChoseTable(item)}
                  >
                    {item.selected ? <CheckIcon boxSize={2} color={"green.500"} /> : <AddIcon boxSize={2} />}
                  </Center>
                </HStack>

                <HSeparator />
              </Box>
            ))}
          <Center w="full" py="10px">
            <UploadDatatableButton
              datasetId={datasetSelected.id}
              onUploadChange={(uploaded) =>
                setDatasetTables(uploaded ? [...datasetTables, { ...uploaded, selected: false }] : datasetTables)
              }
            />
          </Center>
        </>
      )}
    </VStack>
  );
}

const autoConvertFileSize = (size: number) => {
  if (size === 0) return "0 B";
  const units = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const convertedSize = size / Math.pow(1024, i);
  return `${convertedSize.toFixed(2)} ${units[i]}`;
};
