import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  VStack
} from "@chakra-ui/react";
import { useState } from "react";
import CustomInput from "../../../components/CustomInput";

export default function EditNameModal({
  name,
  onUpdate,
  onClose
}: {
  name: string;
  onUpdate: (data: any) => void;
  onClose: () => void;
}) {
  const [updateName, setUpdateName] = useState(name);

  const handleSave = async () => {
    onUpdate(updateName);
  };

  return (
    <Portal>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size={{ base: "sm", md: "md" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="flex-start">
              <CustomInput
                isRequired
                value={updateName}
                onTextChange={(value) => setUpdateName(value)}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              cursor={"pointer"}
              bg="brand.500"
              color="white"
              _hover={{
                bgColor: "brand.600",
                color: "white"
              }}
              onClick={handleSave}
            >
              {"Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
}
