import { DragHandleIcon } from "@chakra-ui/icons";
import { Box, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure } from "@chakra-ui/react";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { isEqual } from "lodash";
import React, { useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import roadmapService from "../../../../services/roadmapService";
import CourseContext from "../context/CourseContext";
import { SortableItem } from "../../../../components/SortableItem";

function ListNode() {
  const { course, level, node, setNode, setLesson, setQuestion, isEditing } = useContext(CourseContext);
  const navigate = useNavigate();
  const [queries] = useSearchParams();
  const type = queries.get("type");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentNode, setCurrentNode] = React.useState<any>(null);
  const [nodeList, setNodeList] = React.useState<any[]>([]);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  );

  const initRoadmap = async () => {
    if (!level) return;
    const { data: { data: list } = { data: {} } } = await roadmapService.getAll({
      courseId: course.id,
      levelId: level.id
    });
    setNodeList(list.rows);
  };

  React.useEffect(() => {
    initRoadmap();
  }, [level]);

  React.useEffect(() => {
    if (isEqual(node, currentNode)) return;
    if (!node) {
      initRoadmap();
      return;
    }

    const updatedList = nodeList.map((item) => (item.id === node.id ? node : item));
    setNodeList(updatedList);
    setCurrentNode(node);
  }, [node]);

  const handleAddNode = async (nodeType: string) => {
    if (isEditing) {
      if (
        confirm(`Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`) ==
        true
      ) {
        return;
      }
    }
    const list = nodeList.filter((node) => node.type === nodeType);
    const obj = {
      name: `${nodeType === "normal-test" ? "Tên node" : nodeType === "practice" ? "Practice node" : "Bài thi"} ${list.length + 1}`,
      courseId: course.id,
      levelId: level.id,
      type: nodeType,
      idx: nodeList.length + 1
    };
    const { data: { data: updatedData } = { data: {} } } = await roadmapService.create(obj);
    setNode(updatedData);
    setNodeList((_prev) => [..._prev, updatedData]);
    navigate(`/course-management/${course.id}/levels/${level.id}/nodes/${updatedData.id}?type=${type}`);
    onClose();
  };

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = nodeList.findIndex((choice) => choice.idx === active.id);
      const newIndex = nodeList.findIndex((choice) => choice.idx === over.id);

      const updatedChoices = arrayMove(nodeList, oldIndex, newIndex).map((choice, index) => ({
        ...choice,
        idx: index + 1
      }));

      try {
        const { data: { data: _updatedData } = { data: {} } } = await roadmapService.arrange(
          updatedChoices.map((choice) => {
            return { id: choice.id, idx: choice.idx };
          })
        );

        setNodeList((_prev: any) => updatedChoices);
      } catch (_error) {}
    }
  };

  if (!level) return null;

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={nodeList.map((choice) => choice.idx!)} strategy={verticalListSortingStrategy}>
          {nodeList.map((choice) => (
            <SortableItem key={choice.idx} id={choice.idx!}>
              {(attributes, listeners) => (
                <Box
                  w="full"
                  display="flex"
                  alignItems="center"
                  gap={4}
                  userSelect={"none"}
                  border={"1px solid rgba(135, 140, 189, 0.3)"}
                  rounded={"10px"}
                  p={"10px"}
                  onClick={() => {
                    if (isEditing) {
                      if (
                        confirm(
                          `Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`
                        ) == true
                      ) {
                        return;
                      }
                    }
                    setNode(choice);
                    setLesson(null);
                    setQuestion(null);
                    navigate(`/course-management/${course.id}/levels/${level.id}/nodes/${choice.id}?type=${type}`);
                  }}
                  {...attributes} // Ensure attributes are applied to Box
                  onPointerDown={(e) => e.stopPropagation()}
                >
                  <DragHandleIcon cursor={"grab"} {...listeners} />

                  <Text>{choice.name}</Text>
                </Box>
              )}
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>

      <Menu isOpen={isOpen}>
        <MenuButton
          w="full"
          border={"1px dashed"}
          py="10px"
          rounded={"10px"}
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          {"Thêm node"}
        </MenuButton>
        <MenuList my="-2" minW="250px" onMouseEnter={onOpen} onMouseLeave={onClose}>
          <MenuItem _hover={{ bg: "gray.200" }} px="14px" onClick={() => handleAddNode("normal-test")}>
            {"Node bài học"}
          </MenuItem>
          <MenuItem _hover={{ bg: "gray.200" }} px="14px" onClick={() => handleAddNode("practice")}>
            {"Node luyện tập"}
          </MenuItem>
          <MenuItem _hover={{ bg: "gray.200" }} px="14px" onClick={() => handleAddNode("final-test")}>
            {"Node bài thi"}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

export default ListNode;
