import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Radio,
  Text,
  VStack
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { COURSE_TYPE } from "../../admin/pages/course/constant";

export default function EditTypeModal({
  type,
  onUpdate,
  onClose
}: {
  type: string;
  onUpdate: (data: any) => void;
  onClose: () => void;
}) {
  const [selectedOption, setSelectedOption] = useState<any>();

  useEffect(() => {
    setSelectedOption(type);
  }, [type]);

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  const handleSave = async () => {
    onUpdate(selectedOption);
  };

  return (
    <Portal>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size={{ base: "sm", md: "md" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="flex-start">
              {Object.entries(COURSE_TYPE).map(([key, value]) => (
                <HStack
                  key={key}
                  w="full"
                  spacing={"8px"}
                  cursor="pointer"
                  py={2}
                  px={4}
                  color={"secondaryGray.900"}
                  _hover={{
                    bg: "brand.500",
                    color: "white"
                  }}
                  onClick={() => handleSelectOption(value)}
                >
                  <Radio isChecked={value === selectedOption} />
                  <Text w="full">{value}</Text>
                </HStack>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              cursor={"pointer"}
              bg="brand.500"
              color="white"
              _hover={{
                bgColor: "brand.600",
                color: "white"
              }}
              onClick={handleSave}
            >
              {"Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
}
