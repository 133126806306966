import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  VStack
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectSpecialities } from "../../../redux/slice";

export default function EditSpecialityModal({
  specialityIds,
  onUpdate,
  onClose
}: {
  specialityIds: string[];
  onUpdate: (data: any) => void;
  onClose: () => void;
}) {
  const specialities = useAppSelector(selectSpecialities);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  useEffect(() => {
    setSelectedOptions(specialityIds);
  }, [specialityIds]);

  const handleSelectOption = (option: string) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const removed = selectedOptions.filter((selected) => selected !== option);
      setSelectedOptions(removed);
    }
  };

  const handleSave = async () => {
    onUpdate(selectedOptions);
  };

  return (
    <Portal>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size={{ base: "sm", md: "md" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="flex-start">
              {specialities.map((speciality: any, index: number) => (
                <HStack
                  key={index}
                  w="full"
                  spacing={"8px"}
                  cursor="pointer"
                  py={2}
                  px={4}
                  color={"secondaryGray.900"}
                  _hover={{
                    bg: "brand.500",
                    color: "white"
                  }}
                  onClick={() => handleSelectOption(speciality.id)}
                >
                  <Checkbox isChecked={selectedOptions.includes(speciality.id)} />
                  <Text w="full">{speciality.name}</Text>
                </HStack>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              cursor={"pointer"}
              bg="brand.500"
              color="white"
              _hover={{
                bgColor: "brand.600",
                color: "white"
              }}
              onClick={handleSave}
            >
              {"Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
}
