import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  Text,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CiNoWaitingSign } from "react-icons/ci";
import { MdCheckCircle } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppToast from "../../../components/AppToast";
import ContentEditor from "../../../components/ContentEditor";
import CustomUploadButton from "../../../components/CustomUploadButton";
import { HSeparator } from "../../../components/Separator";
import courseService from "../../../services/courseService";
import { getErrorMessage } from "../../../utils/helpers";
import EditTypeModal from "../components/EditTypeModal";
import EditVersionPlanModal from "../components/EditVersionPlanModal";
import CourseContext from "../layout/context/CourseContext";
import CustomInput from "../../../components/CustomInput";

type FormType = {
  id?: number;
  name: string;
  type: string;
  specialityIds: any[];
  versionPlan: string;
  description?: string;
  thumbnail?: string;
  updatedAt?: Date;
};

export default function Course() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [queries] = useSearchParams();
  const specialityId = queries.get("specialityId");
  const type = queries.get("type");
  const toast = useToast();
  const { course, setCourse, isEditing, setIsEditing } = React.useContext(CourseContext);

  const [questionList, setQuestionList] = React.useState<any[]>([]);
  const [statistics, setStatistics] = React.useState<any>(null);

  const { isOpen: isOpenTypeModal, onOpen: onOpenTypeModal, onClose: onCloseTypeModal } = useDisclosure();
  const { isOpen: isOpenPlanModal, onOpen: onOpenPlanModal, onClose: onClosePlanModal } = useDisclosure();

  const { control, setValue, reset, watch, handleSubmit: onSubmit } = useForm<FormType>({});

  React.useEffect(() => {
    reset(course);
  }, [course]);

  const initTranscripts = async () => {
    const { data: { data } = { data: {} } } = await courseService.getTranscripts(id, specialityId);

    const questionList = data.reduce((acc: any, item: any) => {
      const roadmapId = item.question.roadmap.id;
      const roadmapName = item.question.roadmap.name;
      const roadmapLevelId = item.question.roadmap.levelId;
      const questionId = item.question.id;
      const questionTitle = item.question.title;
      const questionType = item.question.type;
      const questionLessonId = item.question.lessonId;

      let roadmap = acc.find((r: any) => r.id === roadmapId);
      if (!roadmap) {
        roadmap = { id: roadmapId, name: roadmapName, levelId: roadmapLevelId, questions: [] };
        acc.push(roadmap);
      }

      if (!roadmap.questions.some((q: any) => q.title === questionTitle)) {
        roadmap.questions.push({
          id: questionId,
          title: questionTitle,
          type: questionType,
          lessonId: questionLessonId
        });
      }

      return acc;
    }, []);
    setQuestionList(questionList);

    const statistics = {
      totalQuestions: new Set(data.map((d: any) => d.questionId)).size,
      totalSingleChoice: new Set(
        data.filter((d: any) => d.question.type === "single-choice").map((d: any) => d.questionId)
      ).size,
      totalMultiChoice: new Set(
        data.filter((d: any) => d.question.type === "multi-choice").map((d: any) => d.questionId)
      ).size,
      totalSnippet: new Set(data.filter((d: any) => d.question.type === "snippet").map((d: any) => d.questionId)).size,
      totalHotSpot: new Set(data.filter((d: any) => d.question.type === "hot spot").map((d: any) => d.questionId)).size,
      totalDragAndDrop: new Set(
        data.filter((d: any) => d.question.type === "drag and drop").map((d: any) => d.questionId)
      ).size,
      transcriptScores: data.reduce((acc: any, curr: any) => {
        acc[curr.transcript.nameVi] = (acc[curr.transcript.nameVi] || 0) + parseInt(curr.value);
        return acc;
      }, {})
    };
    setStatistics(statistics);
  };

  React.useEffect(() => {
    if (!specialityId) return;

    initTranscripts();
  }, [specialityId]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataUpdate: any) => {
      return courseService.update(id, dataUpdate);
    },
    onSuccess: async (res: any) => {
      const { data: { data: updatedData } = { data: {} } } = res;
      setCourse(updatedData);
      setIsEditing(false);

      toast({
        description: "Lưu thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
    },
    onError: (error) => {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  });

  const handleUpload = async (name: string, file: string | string[]) => {
    if (name === "thumbnail") {
      setValue(name, typeof file === "object" ? file[0] : (file as string));
      setIsEditing(true);
      return;
    }
  };

  const save: SubmitHandler<FormType> = async (data) => {
    mutate(data);
  };

  if (isEmpty(course)) return null;

  return (
    <>
      {specialityId ? (
        <Box w="full" px="0px">
          <HStack spacing={4} align="flex-start">
            <VStack w="full" spacing={4} align="flex-start">
              <VStack w="full" spacing={2} align="flex-start">
                <Text fontSize={"2xl"} fontWeight={700}>
                  DANH SÁCH CÁC BÀI HỌC CHUYÊN MÔN
                </Text>
              </VStack>
              {questionList?.map((roadmap, idx) => (
                <Box key={idx} w="full">
                  <Text w="full" pb="10px" fontSize={"xl"} fontWeight={700}>
                    {roadmap.name}
                  </Text>
                  <VStack
                    bg={"gray.200"}
                    border={"1px #AABDD9 dashed"}
                    spacing={2}
                    align="flex-start"
                    p="20px"
                    rounded={"10px"}
                  >
                    {roadmap.questions.map((question: any, idx: number) => (
                      <HStack
                        key={idx}
                        bg={"white"}
                        boxShadow={"md"}
                        w="full"
                        spacing={4}
                        align={"center"}
                        px="20px"
                        py="10px"
                        rounded={"10px"}
                        cursor={"pointer"}
                        userSelect={"none"}
                        onClick={() =>
                          navigate(
                            `/course-management/${id}/levels/${roadmap.levelId}/nodes/${roadmap.id}${question.lessonId ? `/lessons/${question.lessonId}` : ""}/questions/${question.id}?type=edit`
                          )
                        }
                      >
                        <Text fontSize={"md"} fontWeight={600}>
                          {question.title}
                        </Text>
                        <Text
                          border={"0.5px #D0D5DD solid"}
                          fontSize={"xs"}
                          fontWeight={600}
                          px="10px"
                          py="2px"
                          rounded={"6px"}
                        >
                          {question.type}
                        </Text>
                      </HStack>
                    ))}
                  </VStack>
                </Box>
              ))}
            </VStack>
            <VStack minW={"300px"} spacing={4} align="flex-start">
              <Box w="full" bg="white" rounded={"20px"}>
                <Text w="full" px="20px" py="10px" fontSize={"2xl"} fontWeight={700}>
                  {"THỐNG KÊ"}
                </Text>
                <HSeparator mb="20px" />
                <VStack spacing={2} align="flex-start" px="20px">
                  <HStack w="full" spacing={4}>
                    <Text w="full" fontSize={"sm"}>
                      {`Tổng số câu hỏi: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {statistics?.totalQuestions}
                      </Text>
                    </Text>
                  </HStack>
                  <HStack w="full" spacing={4}>
                    <Text w="full" fontSize={"sm"}>
                      {`Tổng số câu single choice: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {statistics?.totalSingleChoice}
                      </Text>
                    </Text>
                  </HStack>
                  <HStack w="full" spacing={4}>
                    <Text w="full" fontSize={"sm"}>
                      {`Tổng số câu multiple choice: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {statistics?.totalMultiChoice}
                      </Text>
                    </Text>
                  </HStack>
                  <HStack w="full" spacing={4}>
                    <Text w="full" fontSize={"sm"}>
                      {`Tổng số câu snippet: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {statistics?.totalSnippet}
                      </Text>
                    </Text>
                  </HStack>
                  <HStack w="full" spacing={4}>
                    <Text w="full" fontSize={"sm"}>
                      {`Tổng số câu hot spot: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {statistics?.totalHotSpot}
                      </Text>
                    </Text>
                  </HStack>
                  <HStack w="full" spacing={4}>
                    <Text w="full" fontSize={"sm"}>
                      {`Tổng số câu drag and drop: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {statistics?.totalDragAndDrop}
                      </Text>
                    </Text>
                  </HStack>
                </VStack>

                <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
                  {"Tổng Điểm Kỹ Năng"}
                </Text>

                <VStack spacing={2} align="flex-start" px="20px" pb="20px">
                  {Object.entries(statistics?.transcriptScores ?? {}).map((item: any, idx) => (
                    <CustomInput key={idx} label={item[0]} value={item[1]} readOnly={true} onTextChange={() => {}} />
                  ))}
                </VStack>
              </Box>
            </VStack>
          </HStack>
        </Box>
      ) : (
        <Box w="full" px="0px">
          <HStack spacing={4} align="flex-start">
            <VStack w="full" spacing={4} align="flex-start">
              <VStack w="full" spacing={2} align="flex-start">
                <Text fontSize={"lg"} fontWeight={500}>
                  Mô tả khóa học
                </Text>

                <Controller
                  control={control}
                  name="description"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <FormControl isInvalid={!!error} id="tags">
                      <ContentEditor
                        type={"html"}
                        content={value}
                        onChange={(newValue) => {
                          onChange(newValue);
                          setIsEditing(true);
                        }}
                      />

                      {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                    </FormControl>
                  )}
                />
              </VStack>
            </VStack>
            <VStack minW={{ base: "300px", xl: "450px" }} spacing={4} align="flex-start">
              <Box w="full" bg="white" rounded={"20px"}>
                <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
                  {"Cài đặt khóa học"}
                </Text>
                <HSeparator mb="20px" />
                <VStack spacing={2} align="flex-start" px="20px">
                  <Text w="full" fontSize={"sm"}>
                    {`Trạng thái: `}
                    <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                      {course?.status}
                    </Text>
                  </Text>
                  <Flex w="full" justify={"space-between"} align={"center"}>
                    <Text w="full" fontSize={"sm"}>
                      {`Phiên bản: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {watch("versionPlan")}
                      </Text>
                    </Text>
                    <Text
                      color={"brand.500"}
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      cursor={"pointer"}
                      userSelect={"none"}
                      onClick={onOpenPlanModal}
                    >
                      {"Edit"}
                    </Text>
                  </Flex>
                  <Flex w="full" justify={"space-between"} align={"center"}>
                    <Text w="full" fontSize={"sm"}>
                      {`Loại: `}
                      <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                        {watch("type")}
                      </Text>
                    </Text>
                    <Text
                      color={"brand.500"}
                      fontSize={"sm"}
                      fontWeight={"bold"}
                      cursor={"pointer"}
                      userSelect={"none"}
                      onClick={onOpenTypeModal}
                    >
                      {"Edit"}
                    </Text>
                  </Flex>
                  <Text w="full" fontSize={"sm"}>
                    {`Ngày cập nhật: `}
                    <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                      {dayjs(course.updatedAt).format("DD/MM/YYYY HH:mm a")}
                    </Text>
                  </Text>
                  <Text w="full" fontSize={"sm"}>
                    {`Ngày xuất bản: `}
                    <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                      {course.publishedAt ? dayjs(course.publishedAt).format("DD/MM/YYYY HH:mm a") : "--"}
                    </Text>
                  </Text>
                  <Text w="full" fontSize={"sm"} fontWeight={500}>
                    {`Feature image`}
                  </Text>
                  <CustomUploadButton
                    bg={"secondaryGray.300"}
                    h="100px"
                    label="Add thumbnail image"
                    onUploadChange={handleUpload}
                    name={`thumbnail`}
                    src={watch("thumbnail") ?? ""}
                    thumbnail
                    rounded="8px"
                    w="120px"
                    minW="120px"
                  />
                </VStack>
                <HSeparator mt="20px" />
                <Flex w="full" justify={"space-between"} align={"center"} p={"10px"}>
                  <HStack spacing={2}>
                    <Icon as={isEditing ? CiNoWaitingSign : MdCheckCircle} color={!isEditing && "green.500"} />
                    <Text fontSize={"sm"} fontWeight={600}>
                      {isEditing ? "Chưa lưu" : "Đã lưu"}
                    </Text>
                  </HStack>
                  <HStack spacing={2}>
                    <Button
                      isLoading={isLoading}
                      onClick={onSubmit(save)}
                      border={"1px solid rgba(135, 140, 189, 0.3)"}
                    >
                      {"Lưu"}
                    </Button>
                  </HStack>
                </Flex>
              </Box>
            </VStack>
          </HStack>
          {isOpenPlanModal && (
            <EditVersionPlanModal
              type={"course"}
              plan={watch("versionPlan")}
              onUpdate={(value) => {
                setValue("versionPlan", value);
                setIsEditing(true);
                onClosePlanModal();
              }}
              onClose={onClosePlanModal}
            />
          )}
          {isOpenTypeModal && (
            <EditTypeModal
              type={watch("type")}
              onUpdate={(value) => {
                setValue("type", value);
                setIsEditing(true);
                onCloseTypeModal();
              }}
              onClose={onCloseTypeModal}
            />
          )}
        </Box>
      )}
    </>
  );
}
