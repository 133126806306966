import { DeleteIcon, DragHandleIcon } from "@chakra-ui/icons";
import { Box, Button, Checkbox, HStack, Input, Textarea, VStack } from "@chakra-ui/react";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import { SortableItem } from "../../../../components/SortableItem";
import CustomUploadButton from "../../../../components/CustomUploadButton";

type Choice = {
  name: string;
  isRight: boolean;
  id?: number;
  image?: string;
  feedback?: string;
};

type FormType = {
  choices: Choice[];
};

export default function ListOfChoices({
  choices,
  rightChoices,
  disableChooseRightChoices = false,
  haveArrangement = false,
  isMultiChoices = false,
  onUpdateChoices,
  onUpdateRightChoices
}: {
  choices: Choice[];
  disableChooseRightChoices?: boolean;
  rightChoices: string[];
  haveArrangement?: boolean;
  isMultiChoices?: boolean;
  onUpdateChoices: (data: any) => void;
  onUpdateRightChoices: (data: any) => void;
}) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  );

  const derivedChoices = choices.map((choice, idx) => ({
    ...choice,
    isRight: rightChoices?.includes(choice.name),
    id: idx + 1
  }));

  useEffect(() => {
    reset({
      choices: choices.map((choice, idx) => ({
        ...choice,
        isRight: rightChoices?.includes(choice.name),
        id: idx + 1
      }))
    });
  }, [choices, rightChoices]);

  const { control, setValue, watch, reset } = useForm<FormType>({ defaultValues: { choices: derivedChoices } });

  const currentChoices = watch("choices");

  const addChoice = () => {
    const newChoice: Choice = {
      name: "",
      isRight: false,
      ...(haveArrangement ? { id: currentChoices.length + 1 } : {})
    };
    setValue("choices", [...currentChoices, newChoice]);
  };

  const deleteChoice = (id: number) => {
    const updatedChoices = currentChoices.filter((choice, i) => choice.id !== id);
    setValue("choices", updatedChoices);
  };

  const handleNameChange = (index: number, value: string) => {
    const updatedChoices = [...currentChoices];
    updatedChoices[index].name = value;
    setValue("choices", updatedChoices);
    onUpdateChoices(updatedChoices);
  };

  const handleFeedbackChange = (index: number, value: string) => {
    const updatedChoices = [...currentChoices];
    updatedChoices[index].feedback = value;
    setValue("choices", updatedChoices);
    onUpdateChoices(updatedChoices);
  };

  const handleIsRightChange = (id: number) => {
    const curChoices = watch("choices");
    const updatedChoices = isMultiChoices
      ? curChoices.map((choice, i) => {
          return choice.id === id ? { ...choice, isRight: !choice.isRight } : choice;
        })
      : curChoices.map((choice, i) => ({
          ...choice,
          isRight: choice.id === id
        }));
    setValue("choices", updatedChoices);
    onUpdateRightChoices(updatedChoices.filter((choice) => choice.isRight).map((choice) => choice.name));
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = currentChoices.findIndex((choice) => choice.id === active.id);
      const newIndex = currentChoices.findIndex((choice) => choice.id === over.id);

      const updatedChoices = arrayMove(currentChoices, oldIndex, newIndex).map((choice, index) => ({
        ...choice,
        id: index + 1
      }));

      setValue("choices", updatedChoices);
      onUpdateRightChoices(
        disableChooseRightChoices
          ? updatedChoices
          : updatedChoices.filter((choice) => choice.isRight).map((choice) => choice.name)
      );
      onUpdateChoices(updatedChoices);
    }
  };

  const handleUpload = async (name: string, file: string | string[]) => {
    if (name.includes("image")) {
      const curChoices = watch("choices");
      const idx = name.split(".")[1];
      const updatedChoices = curChoices.map((choice, i) =>
        i === parseInt(idx) ? { ...choice, image: typeof file === "object" ? file[0] : (file as string) } : choice
      );
      setValue("choices", updatedChoices);
      onUpdateChoices(updatedChoices);
    }
  };
  
  return (
    <VStack w="full" align="start" spacing={4}>
      <Button border={"1px dashed"} onClick={addChoice} mb="10px">
        {"Thêm câu trả lời"}
      </Button>
      {haveArrangement ? (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={currentChoices.map((choice) => choice.id!)} strategy={verticalListSortingStrategy}>
            {currentChoices.map((choice, index) => (
              <SortableItem key={choice.id} id={choice.id! as any}>
                {(attributes, listeners) => (
                  <Box
                    w="full"
                    display="flex"
                    alignItems="center"
                    gap={4}
                    userSelect={"none"}
                    {...attributes}
                    onPointerDown={(e) => e.stopPropagation()}
                  >
                    <VStack w="full" spacing={1}>
                      <HStack w="full" spacing={2}>
                        <DragHandleIcon cursor={"grab"} {...listeners} />

                        {!disableChooseRightChoices && (
                          <Checkbox
                            isChecked={choice.isRight}
                            onPointerDown={(e) => e.stopPropagation()}
                            onChange={() => handleIsRightChange(choice.id)}
                          />
                        )}
                        <Controller
                          name={`choices.${index}.name`}
                          control={control}
                          render={() => (
                            <Input
                              defaultValue={choice.name}
                              placeholder="Nhập mô tả câu trả lời..."
                              onClick={(e) => e.stopPropagation()}
                              onPointerDown={(e) => e.stopPropagation()}
                              onChange={(e) => handleNameChange(index, e.target.value)}
                            />
                          )}
                        />
                        <DeleteIcon
                          color="red.500"
                          w="15px"
                          h="15px"
                          cursor={"pointer"}
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteChoice(choice.id);
                          }}
                          onPointerDown={(e) => e.stopPropagation()}
                        />
                      </HStack>
                    </VStack>
                  </Box>
                )}
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      ) : (
        watch("choices").map((choice, index) => (
          <Box w="full" key={index} display="flex" alignItems="center" gap={4}>
            <VStack w="full" spacing={1}>
              <HStack w="full" spacing={2} align={"center"}>
                <VStack w="full" spacing={1} align={"start"}>
                  <HStack w="full" spacing={2}>
                    <Checkbox isChecked={choice.isRight} onChange={() => handleIsRightChange(choice.id)} />
                    <Controller
                      name={`choices.${index}.name`}
                      control={control}
                      render={() => (
                        <Input
                          variant={"unstyled"}
                          defaultValue={choice.name}
                          placeholder="Nhập câu trả lời..."
                          onChange={(e) => handleNameChange(index, e.target.value)}
                        />
                      )}
                    />
                  </HStack>
                  <Box pl="24px">
                    <CustomUploadButton
                      bg={"secondaryGray.300"}
                      h="100px"
                      label="Add thumbnail image"
                      onUploadChange={handleUpload}
                      name={`choices.${index}.image`}
                      src={watch(`choices.${index}.image`) ?? ""}
                      thumbnail
                      rounded="8px"
                      w="120px"
                      minW="120px"
                    />
                  </Box>
                </VStack>
                <DeleteIcon
                  color="red.500"
                  w="15px"
                  h="15px"
                  cursor={"pointer"}
                  onClick={() => deleteChoice(choice.id)}
                />
              </HStack>
              {!isMultiChoices && (
                <Controller
                  name={`choices.${index}.feedback`}
                  control={control}
                  render={() => (
                    <Textarea
                      pl="24px"
                      variant={"unstyled"}
                      defaultValue={choice.feedback}
                      placeholder="Nhập nội dung phản hồi lựa chọn..."
                      onChange={(e) => handleFeedbackChange(index, e.target.value)}
                    />
                  )}
                />
              )}
            </VStack>
          </Box>
        ))
      )}
    </VStack>
  );
}
