import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Center,
  Flex,
  Icon,
  Text,
  useColorModeValue
} from "@chakra-ui/react";
import { useContext } from "react";
import { RiHomeLine } from "react-icons/ri";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import HeaderLinks from "../../admin/layout/navbar/Links";
import CourseContext from "./context/CourseContext";

export default function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [queries] = useSearchParams();
  const type = queries.get("type");
  const parts = location.pathname.split("/").filter(Boolean);
  const { course, level, node, isEditing, setIsEditing } = useContext(CourseContext);

  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBorder = "transparent";
  let gap = "0px";

  const handleRedirect = (path: string) => {
    if (isEditing) {
      if (
        confirm(`Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`) ==
        false
      ) {
        setIsEditing(false);
        navigate(path);
      }
    } else navigate(path);
  };

  return (
    <Box
      position={"fixed"}
      boxShadow={navbarShadow}
      bg={"white"}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={"flex"}
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      px={"16px"}
      py={"8px"}
      top={0}
      right={0}
      w={"calc(100% - 300px)"}
      zIndex={1}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row"
        }}
        alignItems={{ xl: "center" }}
        mb={gap}
      >
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb
            mb="5px"
            separator={<ChevronRightIcon color="gray.500" />}
            color={secondaryText}
            fontSize="md"
            fontWeight={600}
          >
            <BreadcrumbItem>
              <BreadcrumbLink color={secondaryText} onClick={() => handleRedirect(`/admin/courses`)}>
                <Center>
                  <Icon as={RiHomeLine} />
                </Center>
              </BreadcrumbLink>
            </BreadcrumbItem>

            {course && (
              <BreadcrumbItem>
                <BreadcrumbLink
                  color={parts.length <= 2 ? "brand.600" : secondaryText}
                  onClick={() => handleRedirect(`/course-management/${course?.id}?type=${type}`)}
                >
                  {"Quản lý khoá học"}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
            {level && (
              <BreadcrumbItem>
                <BreadcrumbLink
                  color={parts.length <= 4 ? "brand.600" : secondaryText}
                  onClick={() => handleRedirect(`/course-management/${course?.id}/levels/${level?.id}?type=${type}`)}
                >
                  {level.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
            {node && (
              <BreadcrumbItem>
                <BreadcrumbLink
                  color={parts.length <= 6 ? "brand.600" : secondaryText}
                  onClick={() =>
                    handleRedirect(
                      `/course-management/${course?.id}/levels/${level?.id}/nodes/${node?.id}?type=${type}`
                    )
                  }
                >
                  {`Học phần "${node.name}"`}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )}
            {parts.includes("questions") && (
              <BreadcrumbItem>
                <Text color={"brand.600"}>{`Xây dựng học phần`}</Text>
              </BreadcrumbItem>
            )}
          </Breadcrumb>
        </Box>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <HeaderLinks />
        </Box>
      </Flex>
    </Box>
  );
}
