import { isEmpty } from "lodash";
import dataServiceAxios from "./baseService";
import qs from "query-string";
const { stringify } = qs;

const datasetService = {
  create: function (data: any) {
    return dataServiceAxios.post(`core/datasets`, data);
  },
  getAll: function (queryParams: { [key: string]: any }) {
    return dataServiceAxios.get(isEmpty(queryParams) ? `core/datasets` : `core/datasets?${stringify(queryParams)}`);
  },
  getById: function (id: number | string) {
    return dataServiceAxios.get(`core/datasets/${id}`);
  },
  update: function (id: number | string, data: any) {
    return dataServiceAxios.patch(`core/datasets/${id}`, data);
  },
  delete: function (id: number | string) {
    return dataServiceAxios.delete(`core/datasets/${id}`);
  },
  upload: function (id: number | string, data: FormData) {
    return dataServiceAxios.post(`core/datasets/${id}/tables`, data);
  },
  getAllTables: function (id: number | string) {
    return dataServiceAxios.get(`core/datasets/${id}/tables`);
  },
  deleteTable: function (id: number | string, tableId: number | string) {
    return dataServiceAxios.delete(`core/datasets/${id}/tables/${tableId}`);
  }
};

export default datasetService;
