export const NODE_TYPE = {
  NORMAL_TEST: "normal-test",
  PRACTICE: "practice",
  FINAL_TEST: "final-test"
};

export const LESSON_TYPE = {
  VIDEO: "video",
  EXERCISE: "exercise",
  REVISION: "revision"
};

export const LESSON_VERSION = {
  FREE: "free",
  PRO: "pro"
};
