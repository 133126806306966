"use client";

import {
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Text,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AppToast from "../../../../components/AppToast";
import CustomInput from "../../../../components/CustomInput";
import CustomSelect from "../../../../components/CustomSelect";
import roadmapService from "../../../../services/roadmapService";
import { getErrorMessage } from "../../../../utils/helpers";
import { LESSON_TYPE, LESSON_VERSION } from "./constant";

type FormType = {
  title: string;
  type: string;
  versionPlan: string;
};

export default function AddLessonModal({
  data,
  onSave,
  onClose
}: {
  data: any;
  onSave: (data: any) => void;
  onClose: () => void;
}) {
  const toast = useToast();
  const { control, setValue, watch, handleSubmit: onSubmit } = useForm<FormType>({});

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataUpdate: any) => {
      return roadmapService.createLesson(data.nodeId, { ...dataUpdate, idx: data.idx });
    },
    onSuccess: async (res: any) => {
      const { data: { data: updatedData } = { data: {} } } = res;
      onSave(updatedData);

      toast({
        description: "Lưu thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });

      onClose();
    },
    onError: (error) => {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  });

  const save: SubmitHandler<FormType> = async (data) => {
    mutate(data);
  };

  return (
    <Portal>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size={{ base: "sm", md: "md" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <HStack w="full" justify={"space-between"} align={"center"}>
              <Text fontSize={"20px"} fontWeight={600}>
                {"Khởi tạo học phần"}
              </Text>
              <ModalCloseButton rounded="full" border="1px" borderColor="gray.100" top="16px" right="24px" />
            </HStack>
          </ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="flex-start">
              <Controller
                name="title"
                control={control}
                rules={{ required: "Tên không được để trống" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <CustomInput
                    label="Tên học phần"
                    isRequired
                    value={value}
                    error={error}
                    onTextChange={(value) => onChange(value)}
                  />
                )}
              />
              <Controller
                control={control}
                name="type"
                rules={{ required: "Loại không được để trống" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <CustomSelect
                      w="full"
                      placeholder="Chọn loại.."
                      allowAddNew={false}
                      name={"type"}
                      value={[value]}
                      options={Object.entries(LESSON_TYPE).map(([_key, value]) => ({ label: value, value }))}
                      onSelected={(value) => onChange(value[0])}
                    />

                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />
              <Controller
                control={control}
                name="versionPlan"
                rules={{ required: "Phiên bản không được để trống" }}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <FormControl isInvalid={!!error}>
                    <CustomSelect
                      w="full"
                      placeholder="Chọn phiên bản.."
                      allowAddNew={false}
                      name={"type"}
                      value={[value]}
                      options={Object.entries(LESSON_VERSION).map(([_key, value]) => ({ label: value, value }))}
                      onSelected={(value) => onChange(value[0])}
                    />

                    {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                  </FormControl>
                )}
              />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              cursor={"pointer"}
              bg="brand.500"
              color="white"
              _hover={{
                bgColor: "brand.600",
                color: "white"
              }}
              onClick={onSubmit(save)}
            >
              {"Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
}
