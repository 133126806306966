import { Center, Flex, HStack, Icon, IconButton, Text, useToast, VStack } from "@chakra-ui/react";
import React, { useContext } from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppToast from "../../../../components/AppToast";
import courseService from "../../../../services/courseService";
import roadmapService from "../../../../services/roadmapService";
import { getErrorMessage } from "../../../../utils/helpers";
import { LEVEL_VERSION } from "../../../admin/pages/course/constant";
import CourseContext from "../context/CourseContext";
import ListNode from "./ListNode";

function SidebarContent() {
  const { course, setCourse, setLevel, setNode, setLesson, setQuestion, isEditing, setIsEditing } =
    useContext(CourseContext);
  const nagative = useNavigate();
  const [queries] = useSearchParams();
  const parts = location.pathname.split("/").filter(Boolean);
  const toast = useToast();

  const [tabSelected, setTabSelected] = React.useState<number | null>(null);
  const tabRefs = React.useRef<(HTMLDivElement | null)[]>([]);

  const initNode = async (nodeId: number) => {
    const { data: { data } = { data: {} } } = await roadmapService.getById(nodeId);
    setNode(data);
  };

  React.useEffect(() => {
    if (parts.length <= 2) {
      setTabSelected(null);
      setLevel(null);
      setNode(null);
    }
  }, [parts]);

  React.useEffect(() => {
    if (parts.includes("levels")) {
      const levelId = parseInt(parts[3]);
      setTabSelected(levelId);
      const level = course?.levels?.find((l: any) => l.id === levelId);
      setLevel(level);
    }
  }, [parts[3], course]);

  React.useEffect(() => {
    if (parts.includes("nodes")) {
      const nodeId = parseInt(parts[5]);
      initNode(nodeId);
    }
  }, [parts[5]]);

  const handleSelectLevel = async (levelId: number, index: number) => {
    if (isEditing) {
      if (
        confirm(`Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`) ==
        true
      ) {
        return;
      }
    }
    const type = queries.get("type");
    setTabSelected(levelId);
    tabRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center"
    });
    const level = course.levels?.find((l: any) => l.id === levelId);
    setLevel(level);
    setNode(null);
    setLesson(null);
    setQuestion(null);
    nagative(`/course-management/${course.id}/levels/${levelId}?type=${type}`);
  };

  const handleAddLevel = async () => {
    if (isEditing) {
      if (
        confirm(`Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`) ==
        true
      ) {
        return;
      }
    }
    try {
      const { data: { data: updatedData } = { data: {} } } = await courseService.createLevel(course.id, {
        name: `Cấp độ ${(course.levels?.length ?? 0) + 1}`,
        idx: (course.levels?.length ?? 0) + 1,
        versionPlan: LEVEL_VERSION.FREE
      });
      setCourse(updatedData);

      const levelId = updatedData.levels[updatedData.levels.length - 1].id;
      handleSelectLevel(levelId, updatedData.levels.length - 1);
      nagative(`/course-management/${course.id}/levels/${levelId}?type=${queries.get("type")}`);
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  // SIDEBAR
  return (
    <Flex direction="column" height="calc(100vh - 120px)" maxH="calc(100vh - 120px)" overflow={"auto"}>
      <VStack mb="auto" h="100vh" px={"10px"}>
        <HStack w="full" maxW="full" overflow={"auto"} align={"center"} bg={"gray.200"} rounded={"10px"} p={"10px"}>
          <HStack w="full" maxW="full" overflow={"auto"}>
            {course?.levels?.map((level: any, index: number) => (
              <Center
                key={index}
                ref={(el) => (tabRefs.current[index] = el)}
                minW="max-content"
                h="full"
                bg={tabSelected === level.id ? "black" : "transparent"}
                color={tabSelected === level.id ? "white" : "black"}
                rounded={"10px"}
                cursor="pointer"
                p="8px"
                userSelect={"none"}
                onClick={() => handleSelectLevel(level.id, index)}
              >
                <Text color="contentPrimary" fontSize="14px" fontWeight="500">
                  {level.name}
                </Text>
              </Center>
            ))}
          </HStack>
          <IconButton
            aria-label="add level"
            bg="black"
            size="xs"
            icon={<Icon as={FaPlus} color={"white"} />}
            onClick={handleAddLevel}
          />
        </HStack>
        <ListNode />
      </VStack>
    </Flex>
  );
}

export default SidebarContent;
