import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Icon,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { BiEditAlt } from "react-icons/bi";
import { CiNoWaitingSign } from "react-icons/ci";
import { FaPlus } from "react-icons/fa6";
import { IoChevronBack } from "react-icons/io5";
import { MdCheckCircle } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AppToast from "../../../../components/AppToast";
import ContentEditor from "../../../../components/ContentEditor";
import CustomConfirmButton from "../../../../components/CustomConfirmButton";
import { CustomInputNumber } from "../../../../components/CustomNumberInput";
import CustomSelect from "../../../../components/CustomSelect";
import { HSeparator } from "../../../../components/Separator";
import roadmapService from "../../../../services/roadmapService";
import { getErrorMessage } from "../../../../utils/helpers";
import EditNameModal from "../../components/EditNameModal";
import CourseContext from "../../layout/context/CourseContext";
import { countBoldItalicTexts } from "./CountAnswers";
import DatasetOfQuestion from "./DatasetOfQuestion";
import EditTypeModal from "./EditTypeModal";
import ListOfChoices from "./ListOfChoices";
import RoadmapQuestionTranscript from "./RoadmapQuestionTranscript";

type FormType = {
  id?: number;
  title: string;
  type: string;
  idx: number;
  description: string;
  answer: any;
  answerChoices: any;
  sandbox?: any;
  hint?: string;
  guide?: string;
  exp?: number;
  nut?: number;
  time?: number;
  transcripts: any[];
  rightFeedback?: string;
  wrongFeedback?: string;
  updatedAt?: Date;
};

export default function Question() {
  const { id, levelId, nodeId, lessonId, questionId } = useParams();
  const [queries] = useSearchParams();
  const type = queries.get("type");
  const navigate = useNavigate();
  const toast = useToast();
  const { node, isEditing, setIsEditing } = React.useContext(CourseContext);

  const { control, setValue, reset, watch, handleSubmit: onSubmit } = useForm<FormType>({});
  const questionType = watch("type");
  const answer = watch("answer");
  const answerChoices = watch("answerChoices");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenType, onOpen: onOpenType, onClose: onCloseType } = useDisclosure();

  React.useEffect(() => {
    if (questionId === "new") {
      reset({
        title: "",
        type: "single-choice",
        idx: 1,
        description: "",
        answer: [],
        answerChoices: [],
        sandbox: [],
        hint: "",
        guide: "",
        exp: 0,
        nut: 0,
        transcripts: [],
        updatedAt: null
      });
      return;
    }

    roadmapService.getByQuestionId(nodeId, questionId).then((res) => {
      const { data: { data: questionData = { data: {} } } = { data: {} } } = res;

      reset(questionData);
    });
  }, [id, questionId]);

  const { mutate, isLoading } = useMutation({
    mutationFn: (dataUpdate: any) => {
      return questionId === "new"
        ? roadmapService.createQuestion(nodeId, { ...dataUpdate, lessonId })
        : roadmapService.updateQuestion(nodeId, questionId, dataUpdate);
    },
    onSuccess: async (res: any) => {
      const { data: { data: _updatedData } = { data: {} } } = res;
      setIsEditing(false);
      if (questionId === "new") navigate(-1);

      toast({
        description: "Lưu thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
    },
    onError: (error) => {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  });

  const onChangeAnswerChoices = (value: string[], index: number) => {
    setValue("answerChoices", {
      ...answerChoices,
      [index]: value
    });
    setIsEditing(true);
  };

  const save: SubmitHandler<FormType> = async (data) => {
    mutate(data);
  };

  const confirmDelete = async () => {
    const { data: { data: _updated } = { data: {} } } = await roadmapService.deleteQuestion(nodeId, questionId);

    navigate(
      `/course-management/${id}/levels/${levelId}/nodes/${nodeId}${lessonId ? `/lessons/${lessonId}` : ""}?type=${type}`
    );
  };

  const handleChangeTranscripts = (transcriptId: number, value: number) => {
    const transcripts = watch("transcripts");

    // if transcriptId is not exist in transcripts, add new transcript
    const isExist = transcripts?.some((item: any) => item.transcriptId === transcriptId);
    if (!isExist) {
      const newTranscript = {
        transcriptId,
        value
      };
      setValue("transcripts", [...(transcripts ?? []), newTranscript]);
      setIsEditing(true);
      return;
    }

    // if transcriptId is exist in transcripts, update value of transcript
    const updatedTranscripts = transcripts.map((item: any) => {
      if (item.transcriptId === transcriptId) {
        return {
          ...item,
          value
        };
      }
      return item;
    });

    setValue("transcripts", updatedTranscripts);
    setIsEditing(true);
  };

  return (
    <Box w="full" px="0px">
      <Flex w={"full"} justify="space-between" align="center" mb={"20px"}>
        <HStack>
          <Icon
            as={IoChevronBack}
            cursor={"pointer"}
            userSelect={"none"}
            onClick={() => {
              if (isEditing) {
                if (
                  confirm(
                    `Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`
                  ) == true
                ) {
                  return;
                }
                setIsEditing(false);
              }
              navigate(
                `/course-management/${id}/levels/${levelId}/nodes/${nodeId}${lessonId ? `/lessons/${lessonId}` : ""}?type=${type}`
              );
            }}
          />
          <HStack>
            <Text fontSize={"3xl"} fontWeight={800} whiteSpace={"nowrap"}>
              {watch("title")}
            </Text>
            <Icon as={BiEditAlt} cursor={"pointer"} onClick={onOpen} />
          </HStack>
        </HStack>
        <HStack spacing={2}>
          <CustomConfirmButton
            title={`Xoá câu hỏi`}
            question={"Are you sure to delete this Question?"}
            leftIcon={<DeleteIcon />}
            color={"black"}
            data={node}
            onConfirm={confirmDelete}
            border={"1px solid rgba(135, 140, 189, 0.3)"}
            h="32px"
            rounded={"20px"}
          />

          <IconButton
            aria-label="add question"
            bg="black"
            w="30px"
            minW="0px"
            h="30px"
            minH="0px"
            icon={<Icon as={FaPlus} color={"white"} />}
            onClick={() => {
              if (isEditing) {
                if (
                  confirm(
                    `Chưa lưu các thay đổi\n\nBạn có muốn lưu các thay đổi trước khi thoát khỏi màn hình này không?`
                  ) == true
                ) {
                  return;
                }
                setIsEditing(false);
              }
              navigate(
                `/course-management/${id}/levels/${levelId}/nodes/${nodeId}${lessonId ? `/lessons/${lessonId}` : ""}/questions/new?type=${type}`
              );
            }}
          />
        </HStack>
      </Flex>
      <HStack spacing={6} align={"flex-start"}>
        <Tabs w="full" maxW={{ base: "calc(100% - 300px - 1.5rem)", xl: "calc(100% - 450px - 1.5rem)" }}>
          <TabList>
            <Tab>Nội dung câu hỏi</Tab>
            {lessonId && <Tab>Điểm kỹ năng</Tab>}
          </TabList>

          <TabPanels>
            <TabPanel px={0}>
              <VStack spacing={4} align="flex-start">
                <VStack w="full" align="flex-start" spacing={1}>
                  <Text fontWeight={"bold"}>{"Đặt câu hỏi"}</Text>
                  {(questionId === "new" || (questionId != "new" && !isEmpty(watch("description")))) && (
                    <Controller
                      control={control}
                      name="description"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} id="description">
                          <ContentEditor
                            type={"html"}
                            content={value}
                            onChange={(newValue) => {
                              onChange(newValue);
                              setIsEditing(true);
                            }}
                          />

                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  )}
                </VStack>

                {questionType === "hot-spot" && (
                  <VStack w="full" bg="white" px={"20px"} py={"10px"} rounded={"20px"} align="flex-start" spacing={2}>
                    <Text fontWeight={"bold"}>{"Mô tả câu trả lời"}</Text>
                    <VStack w="full" align="flex-start" spacing={1}>
                      <Text>{"Đáp án"}</Text>
                      <Controller
                        control={control}
                        name="answer"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormControl isInvalid={!!error} id="answer">
                            <ContentEditor
                              type={"json"}
                              content={value}
                              onChange={(newValue) => {
                                onChange(newValue);
                                setIsEditing(true);
                              }}
                            />

                            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                          </FormControl>
                        )}
                      />
                    </VStack>
                    <VStack w="full" align="flex-start" spacing={1}>
                      <Text>{"Danh sách lựa chọn"}</Text>
                      {Array.from(
                        { length: !isEmpty(answer) ? countBoldItalicTexts(answer.content) : 0 },
                        (_, index) => (
                          <Flex key={index} w="full" align="center" gap="10px">
                            <CustomSelect
                              w="full"
                              placeholder={`Chọn danh sách câu trả lời cho lỗ trống ${index + 1}..`}
                              multiple
                              name={`answerChoices-${index}`}
                              value={answerChoices?.[index]}
                              options={
                                !isEmpty(answerChoices)
                                  ? answerChoices?.[index]?.map((item: string) => ({
                                      label: item,
                                      value: item
                                    }))
                                  : []
                              }
                              onSelected={(value) => onChangeAnswerChoices(value, index)}
                            />
                          </Flex>
                        )
                      )}
                    </VStack>

                    <Controller
                      control={control}
                      name="rightFeedback"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} id="answer">
                          <Textarea
                            defaultValue={value}
                            placeholder="Nhập nội dung phản hồi khi trả lời đúng..."
                            onChange={(e) => onChange(e.target.value)}
                          />

                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={control}
                      name="wrongFeedback"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} id="answer">
                          <Textarea
                            defaultValue={value}
                            placeholder="Nhập nội dung phản hồi khi trả lời sai..."
                            onChange={(e) => onChange(e.target.value)}
                          />

                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </VStack>
                )}

                {questionType === "snippet" && (
                  <>
                    <VStack w="full" bg="white" py={"10px"} rounded={"20px"} align="flex-start" spacing={2}>
                      <Text px={"20px"} fontWeight={"bold"}>{"Dữ liệu đề bài"}</Text>
                      <HSeparator mb="20px" />
                      <DatasetOfQuestion
                        sandbox={watch("sandbox") ?? []}
                        onUpdateSanbox={(data) => setValue("sandbox", data)}
                      />
                    </VStack>
                    <VStack w="full" bg="white" px={"20px"} py={"10px"} rounded={"20px"} align="flex-start" spacing={2}>
                      {/* <Text fontWeight={"bold"}>{"Sandbox"}</Text>
                      <SanboxOfQuestion
                        sandbox={watch("sandbox") ?? []}
                        onUpdateSanbox={(data) => setValue("sandbox", data)}
                      /> */}

                      <Text fontWeight={"bold"} pt="20px">
                        {"Mô tả câu trả lời"}
                      </Text>
                      <VStack w="full" align="flex-start" spacing={1}>
                        <Text>{"Đáp án"}</Text>
                        <Controller
                          control={control}
                          name="answer"
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <FormControl isInvalid={!!error} id="answer">
                              <ContentEditor
                                type={"json"}
                                content={value}
                                onChange={(newValue) => {
                                  onChange(newValue);
                                  setIsEditing(true);
                                }}
                              />

                              {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                            </FormControl>
                          )}
                        />
                      </VStack>
                      <VStack w="full" align="flex-start" spacing={1}>
                        <Text>{"Danh sách lựa chọn"}</Text>
                        <ListOfChoices
                          choices={answerChoices ?? []}
                          rightChoices={[]}
                          disableChooseRightChoices={true}
                          haveArrangement={true}
                          onUpdateChoices={(choices) => {
                            setValue("answerChoices", choices);
                            setIsEditing(true);
                          }}
                          onUpdateRightChoices={() => {}}
                        />
                      </VStack>

                      <Controller
                        control={control}
                        name="rightFeedback"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormControl isInvalid={!!error} id="answer">
                            <Textarea
                              defaultValue={value}
                              placeholder="Nhập nội dung phản hồi khi trả lời đúng..."
                              onChange={(e) => onChange(e.target.value)}
                            />

                            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                          </FormControl>
                        )}
                      />
                      <Controller
                        control={control}
                        name="wrongFeedback"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <FormControl isInvalid={!!error} id="answer">
                            <Textarea
                              defaultValue={value}
                              placeholder="Nhập nội dung phản hồi khi trả lời sai..."
                              onChange={(e) => onChange(e.target.value)}
                            />

                            {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                          </FormControl>
                        )}
                      />
                    </VStack>
                  </>
                )}

                {questionType === "multi-choices" && (
                  <VStack w="full" bg="white" px={"20px"} py={"10px"} rounded={"20px"} align="flex-start" spacing={2}>
                    <Text fontWeight={"bold"}>{"Danh sách câu trả lời"}</Text>
                    <ListOfChoices
                      choices={answerChoices ?? []}
                      rightChoices={answer ?? []}
                      isMultiChoices={true}
                      onUpdateChoices={(choices) => {
                        setValue("answerChoices", choices);
                        setIsEditing(true);
                      }}
                      onUpdateRightChoices={(rightChoices) => {
                        setValue("answer", rightChoices);
                        setIsEditing(true);
                      }}
                    />

                    <Controller
                      control={control}
                      name="rightFeedback"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} id="answer">
                          <Textarea
                            defaultValue={value}
                            placeholder="Nhập nội dung phản hồi khi trả lời đúng..."
                            onChange={(e) => onChange(e.target.value)}
                          />

                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={control}
                      name="wrongFeedback"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} id="answer">
                          <Textarea
                            defaultValue={value}
                            placeholder="Nhập nội dung phản hồi khi trả lời sai..."
                            onChange={(e) => onChange(e.target.value)}
                          />

                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </VStack>
                )}

                {questionType === "single-choice" && (
                  <VStack w="full" bg="white" px={"20px"} py={"10px"} rounded={"20px"} align="flex-start" spacing={2}>
                    <Text fontWeight={"bold"}>
                      {"Danh sách câu trả lời"}
                      <Text as="span" fontSize={"sm"} fontWeight={"normal"} pl="10px">
                        {"(Chỉ chọn 1 câu trả lời đúng)"}
                      </Text>
                    </Text>
                    <ListOfChoices
                      choices={answerChoices ?? []}
                      rightChoices={answer ?? []}
                      onUpdateChoices={(choices) => {
                        setValue("answerChoices", choices);
                        setIsEditing(true);
                      }}
                      onUpdateRightChoices={(rightChoices) => {
                        setValue("answer", rightChoices);
                        setIsEditing(true);
                      }}
                    />
                  </VStack>
                )}

                {questionType === "drag-drop" && (
                  <VStack w="full" bg="white" px={"20px"} py={"10px"} rounded={"20px"} align="flex-start" spacing={2}>
                    <Text fontWeight={"bold"}>
                      {"Danh sách câu trả lời"}
                      <Text as="span" fontSize={"sm"} fontWeight={"normal"} pl="10px">
                        {"(Kéo thả để sắp xếp thứ tự đúng)"}
                      </Text>
                    </Text>
                    <ListOfChoices
                      choices={answerChoices ?? []}
                      rightChoices={answer ?? []}
                      haveArrangement={true}
                      isMultiChoices={true}
                      onUpdateChoices={(choices) => {
                        setValue("answerChoices", choices);
                        setIsEditing(true);
                      }}
                      onUpdateRightChoices={(rightChoices) => {
                        setValue("answer", rightChoices);
                        setIsEditing(true);
                      }}
                    />

                    <Controller
                      control={control}
                      name="rightFeedback"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} id="answer">
                          <Textarea
                            defaultValue={value}
                            placeholder="Nhập nội dung phản hồi khi trả lời đúng..."
                            onChange={(e) => onChange(e.target.value)}
                          />

                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                    <Controller
                      control={control}
                      name="wrongFeedback"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <FormControl isInvalid={!!error} id="answer">
                          <Textarea
                            defaultValue={value}
                            placeholder="Nhập nội dung phản hồi khi trả lời sai..."
                            onChange={(e) => onChange(e.target.value)}
                          />

                          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
                        </FormControl>
                      )}
                    />
                  </VStack>
                )}
              </VStack>
            </TabPanel>
            <TabPanel px={0}>
              <RoadmapQuestionTranscript transtripts={watch("transcripts")} onChange={handleChangeTranscripts} />
            </TabPanel>
          </TabPanels>
        </Tabs>
        <VStack minW={{ base: "300px", xl: "450px" }} spacing={4} align="flex-start">
          <Box w="full" bg="white" rounded={"20px"}>
            <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
              {"Thông tin câu hỏi"}
            </Text>
            <HSeparator mb="20px" />
            <VStack spacing={4} align="flex-start" px="20px">
              <Flex w="full" justify={"space-between"} align={"center"}>
                <Text w="full" fontSize={"sm"}>
                  {`Loại câu hỏi: `}
                  <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                    {watch("type")}
                  </Text>
                </Text>
                <Text
                  color={"brand.500"}
                  fontSize={"sm"}
                  fontWeight={"bold"}
                  cursor={"pointer"}
                  userSelect={"none"}
                  onClick={onOpenType}
                >
                  {"Edit"}
                </Text>
              </Flex>
              <Text w="full" fontSize={"sm"}>
                {`Ngày cập nhật: `}
                <Text as="span" fontSize={"sm"} fontWeight={"bold"}>
                  {watch("updatedAt") ? dayjs(watch("updatedAt")).format("DD/MM/YYYY HH:mm a") : "--"}
                </Text>
              </Text>
            </VStack>
            <HSeparator mt="20px" />
            <Flex w="full" justify={"space-between"} align={"center"} p={"10px"}>
              <HStack spacing={2}>
                <Icon as={isEditing ? CiNoWaitingSign : MdCheckCircle} color={!isEditing && "green.500"} />
                <Text fontSize={"sm"} fontWeight={600}>
                  {isEditing ? "Chưa lưu" : "Đã lưu"}
                </Text>
              </HStack>
              <HStack spacing={2}>
                <Button isLoading={isLoading} onClick={onSubmit(save)} border={"1px solid rgba(135, 140, 189, 0.3)"}>
                  {"Lưu"}
                </Button>
              </HStack>
            </Flex>
          </Box>

          {lessonId && (
            <Box w="full" bg="white" rounded={"20px"} pb={"30px"}>
              <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
                {"Phần thưởng"}
              </Text>
              <HSeparator mb="20px" />
              <VStack spacing={4} align="flex-start" px="20px">
                <Text w="full" fontSize={"sm"} fontWeight={500}>
                  {`Kinh nghiệm`}
                </Text>
                <Controller
                  name="exp"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <CustomInputNumber
                      value={value ?? 0}
                      error={error}
                      onChange={(value) => {
                        onChange(value);
                        setIsEditing(true);
                      }}
                    />
                  )}
                />
                <Text w="full" fontSize={"sm"} fontWeight={500}>
                  {`Hạt dẻ`}
                </Text>
                <Controller
                  name="nut"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <CustomInputNumber
                      value={value ?? 0}
                      error={error}
                      onChange={(value) => {
                        onChange(value);
                        setIsEditing(true);
                      }}
                    />
                  )}
                />
              </VStack>
            </Box>
          )}

          {!lessonId && (
            <Box w="full" bg="white" rounded={"20px"} pb={"30px"}>
              <Text w="full" px="20px" py="10px" fontSize={"xl"} fontWeight={700}>
                {"Cấu hình câu hỏi"}
              </Text>
              <HSeparator mb="20px" />
              <VStack spacing={4} align="flex-start" px="20px">
                <Text w="full" fontSize={"sm"} fontWeight={500}>
                  {`Thời gian trả lời (giây)`}
                </Text>
                <Controller
                  name="time"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <CustomInputNumber
                      value={value ?? 0}
                      error={error}
                      onChange={(value) => {
                        onChange(value);
                        setIsEditing(true);
                      }}
                    />
                  )}
                />
              </VStack>
            </Box>
          )}
        </VStack>
      </HStack>
      {isOpen && (
        <EditNameModal
          name={watch("title")}
          onUpdate={(value) => {
            setValue("title", value);
            setIsEditing(true);
            onClose();
          }}
          onClose={onClose}
        />
      )}
      {isOpenType && (
        <EditTypeModal
          type={watch("type")}
          onUpdate={(value) => {
            setValue("type", value);
            setIsEditing(true);
            onCloseType();
          }}
          onClose={onCloseType}
        />
      )}
    </Box>
  );
}
