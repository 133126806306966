import { DeleteIcon } from "@chakra-ui/icons";
import { HStack, Icon, IconButton, Text, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { isEmpty } from "lodash";
import React, { useContext } from "react";
import { BiEditAlt } from "react-icons/bi";
import { FaPlus } from "react-icons/fa6";
import { IoTrashBin } from "react-icons/io5";
import { MdCancel, MdCheckCircle, MdOutlinePublic, MdOutlinePublicOff } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import AppToast from "../../../components/AppToast";
import CustomConfirmButton from "../../../components/CustomConfirmButton";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { selectUser } from "../../../redux/slice";
import courseService from "../../../services/courseService";
import { USER_ROLE } from "../../../utils/constants";
import { getErrorMessage } from "../../../utils/helpers";
import { COURSE_STATUS } from "../../admin/pages/course/constant";
import EditNameModal from "../components/EditNameModal";
import CourseContext from "./context/CourseContext";
import EditSpecialityModal from "../components/EditSpecialityModal";

export default function CourseInfo() {
  const navigate = useNavigate();
  const [queries] = useSearchParams();
  const type = queries.get("type");
  const { course, setCourse, isEditing, setIsEditing } = useContext(CourseContext);
  const user = useAppSelector(selectUser);
  const parts = location.pathname.split("/").filter(Boolean);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenSpec, onOpen: onOpenSpec, onClose: onCloseSpec } = useDisclosure();

  const [specialities, setSpecialities] = React.useState([]);
  const [isDisplay, setIsDisplay] = React.useState(true);

  const init = async () => {
    const id = parts[1];

    if (isEmpty(id)) return;
    const { data: { data } = { data: {} } } = await courseService.getById(id);
    setCourse(data);
    setIsEditing(false);
  };

  const initSpecialities = async () => {
    if (isEmpty(course)) return;
    const promises = course?.specialityIds?.map(async (specialityId: number) => {
      const res = await courseService.getAllQuestionById(course?.id, specialityId);
      return res?.data?.data;
    });

    const results = await Promise.all(promises);
    setSpecialities(results);
  };

  React.useEffect(() => {
    init();
  }, [parts[1]]);

  React.useEffect(() => {
    initSpecialities();
    if (!isEmpty(type) && type === "edit" && [COURSE_STATUS.DRAFT, COURSE_STATUS.PRIVATE].includes(course?.status))
      setIsDisplay(false);
  }, [course, type]);

  React.useEffect(() => {
    initSpecialities();
  }, []);

  let secondaryText = useColorModeValue("gray.700", "white");
  let gap = "0px";

  const confirmDelete = async (id: number) => {
    try {
      const { data: { data: _updated } = { data: {} } } = await courseService.delete(id);

      toast({
        description: "Xóa khóa học thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
      navigate("/admin/courses");
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  const confirmDeletePermanantly = async (id: number) => {
    try {
      const { data: { data: _updated } = { data: {} } } = await courseService.deletePermanently(id);

      toast({
        description: "Khóa học đã xóa vĩnh viễn!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
      navigate("/admin/courses");
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  const confirmPublish = async (id: number) => {
    try {
      const { data: { data: _updated } = { data: {} } } = await courseService.publish(id);

      init();
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  const confirmUnPublish = async (id: number) => {
    try {
      const { data: { data: _updated } = { data: {} } } = await courseService.unPublish(id);

      init();
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  const confirmApprove = async (id: number) => {
    try {
      const { data: { data: _updated } = { data: {} } } = await courseService.approve(id);

      init();
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  const confirmReject = async (id: number) => {
    try {
      const { data: { data: _updated } = { data: {} } } = await courseService.reject(id);

      init();
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  const confirmDisApprove = async (id: number) => {
    try {
      const { data: { data: _updated } = { data: {} } } = await courseService.disapprove(id);

      init();
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  const handleSave = async (data: any) => {
    try {
      const { data: { data: _updatedData } = { data: {} } } = await courseService.update(course.id, data);
      setCourse({ ...course, ...data });

      toast({
        description: "Lưu thành công!",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
    } catch (error) {
      const message = getErrorMessage(error);

      toast({
        position: "top-right",
        render: ({ onClose }) => <AppToast status={"error"} subtitle={message} onClose={onClose} />
      });
    }
  };

  if (isEmpty(course)) return null;

  return (
    <VStack
      w="full"
      align={"start"}
      bg="white"
      borderBottom={"1px solid rgba(135, 140, 189, 0.3)"}
      mb={gap}
      px={"20px"}
      pb={"20px"}
      spacing={"4px"}
    >
      <HStack w="full" align={"flex-end"} spacing={"20px"}>
        <VStack w="full" spacing={2} align="flex-start">
          <HStack w="full" align={"center"}>
            <Text fontSize="2xl" color={secondaryText} fontWeight="bold">
              {course?.name}
            </Text>
            {!isDisplay && <Icon as={BiEditAlt} cursor={"pointer"} onClick={onOpen} />}
          </HStack>

          <Text w="full" fontSize="sm" color={"gray.400"}>
            {`Cập nhật lần cuối • ${dayjs(course?.updatedAt).format("DD [Tháng] MM,YYYY HH:mm A")}`}
          </Text>
        </VStack>
        <HStack w={"500px"} minW={{ base: "300px", xl: "450px" }} justify={"flex-end"} align={"center"}>
          {["draft", "private"].includes(course.status) && course.userId === user.id && (
            <>
              <CustomConfirmButton
                variant={"brand"}
                size={"sm"}
                title={`Xuất bản`}
                question={"Are you sure to publish this Course?"}
                leftIcon={<Icon as={MdOutlinePublic} />}
                data={course}
                onConfirm={() => confirmPublish(course.id)}
              />
              <CustomConfirmButton
                colorScheme={"red"}
                size={"sm"}
                title={`Xóa`}
                question={"Are you sure to delete this Course?"}
                leftIcon={<DeleteIcon />}
                data={course}
                onConfirm={() => confirmDelete(course.id)}
              />
            </>
          )}
          {course.status === "pending" && user.roleId === USER_ROLE.ADMIN && (
            <>
              <CustomConfirmButton
                title={`Huỷ bỏ xuất bản`}
                question={"Are you sure to revert publish this Course?"}
                leftIcon={<Icon as={MdOutlinePublicOff} />}
                color="orange.500"
                data={course}
                onConfirm={() => confirmUnPublish(course.id)}
              />
              <CustomConfirmButton
                title={`Duyệt xuất bản`}
                question={"Are you sure to approve this Course?"}
                leftIcon={<Icon as={MdCheckCircle} />}
                color="green.500"
                data={course}
                onConfirm={() => confirmApprove(course.id)}
              />
              <CustomConfirmButton
                title={`Từ chối xuất bản`}
                question={"Are you sure to reject this Course?"}
                leftIcon={<Icon as={MdCancel} />}
                color="red.500"
                data={course}
                onConfirm={() => confirmReject(course.id)}
              />
            </>
          )}
          {course.status === "published" && user.roleId === USER_ROLE.ADMIN && (
            <CustomConfirmButton
              title={`Huỷ xuất bản`}
              question={"Are you sure to make this Course private?"}
              leftIcon={<Icon as={MdOutlinePublicOff} />}
              color="orange.500"
              data={course}
              onConfirm={() => confirmDisApprove(course.id)}
            />
          )}
          {course.status === "deleted" && user.roleId === USER_ROLE.ADMIN && (
            <CustomConfirmButton
              title={`Xoá vĩnh viễn`}
              question={"Are you sure to delete this Course permanently?"}
              leftIcon={<Icon as={IoTrashBin} />}
              color="black"
              data={course}
              onConfirm={() => confirmDeletePermanantly(course.id)}
            />
          )}
        </HStack>
      </HStack>
      <HStack w="full" align={"center"} spacing={"10px"} pt={"6px"}>
        {specialities?.map((data: any, index: number) => {
          const count = new Set(data.transcripts.map((transcript: any) => transcript.questionId)).size;

          return (
          <HStack
            key={index}
            align={"center"}
            border="1px solid"
            borderColor={"gray.300"}
            px={"8px"}
            py={"4px"}
            rounded={"8px"}
            cursor={"pointer"}
            onClick={() => navigate(`/course-management/${course.id}?specialityId=${data.id}&type=${type}`)}
          >
            <Text size="sm" fontSize="sm">
              {data.name}
            </Text>
            <Text bg={"brand.600"} color={"white"} fontSize="sm" px={"6px"} rounded={"10px"} userSelect={"none"}>
              {count ?? 0}
            </Text>
          </HStack>
        )})}
        <IconButton
          aria-label="add speciality"
          bg="black"
          size="xs"
          icon={<Icon as={FaPlus} color={"white"} />}
          onClick={onOpenSpec}
        />
      </HStack>
      {isOpen && (
        <EditNameModal
          name={course.name}
          onUpdate={(value) => {
            handleSave({ name: value });
            onClose();
          }}
          onClose={onClose}
        />
      )}
      {isOpenSpec && (
        <EditSpecialityModal
          specialityIds={course?.specialityIds}
          onUpdate={(value) => {
            handleSave({ specialityIds: value });
            onCloseSpec();
          }}
          onClose={onCloseSpec}
        />
      )}
    </VStack>
  );
}
