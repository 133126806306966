import { Box, HStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Route, Routes, useSearchParams } from "react-router-dom";
import Course from "../pages/Course";
import routes from "../routes";
import Navbar from ".//Navbar";
import CourseContext from "./context/CourseContext";
import CourseInfo from "./CourseInfo";
import Sidebar from "./sidebar";

// Custom Chakra theme
export default function Layout(props: any) {
  const [course, setCourse] = React.useState();
  const [level, setLevel] = React.useState();
  const [node, setNode] = React.useState();
  const [lesson, setLesson] = React.useState();
  const [question, setQuestion] = React.useState();
  const [isEditing, setIsEditing] = React.useState(false);
  const { children, ...rest } = props;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.has("type")) {
      setSearchParams({ type: "view" });
    }
  }, [searchParams]);

  // functions for changing the states from components
  const getRoute = () => {
    return window.location.pathname !== "/course-management";
  };

  const flattenRoutes = (routes: RoutesType[]): RoutesType[] => {
    return routes.reduce((acc: RoutesType[], route: RoutesType) => {
      // Thêm route hiện tại vào danh sách phẳng
      acc.push(route);
      // Nếu route có children, duyệt qua các children và thêm vào danh sách phẳng
      if (route.children) {
        acc.push(...flattenRoutes(route.children));
      }
      return acc;
    }, []);
  };

  const getRoutes = (routes: RoutesType[]): any => {
    const flatRoutes = flattenRoutes(routes);
    return flatRoutes.map((route: RoutesType, key: any) => {
      if (route.component && !route.noLayout) {
        return <Route path={route.path} element={route.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <CourseContext.Provider
      value={{
        course,
        setCourse,
        level,
        setLevel,
        node,
        setNode,
        lesson,
        setLesson,
        question,
        setQuestion,
        isEditing,
        setIsEditing
      }}
    >
      <HStack w="full" spacing={0}>
        <Sidebar routes={routes} {...rest} />
        <Box
          w={"full"}
          height="100%"
          overflow="auto"
          position="relative"
          minHeight="100vh"
          maxHeight="100vh"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
          pt={"70px"}
        >
          <Navbar />
          <CourseInfo />

          {getRoute() ? (
            <Box mx="auto" px={"20px"} py={"10px"}>
              <Routes>
                {getRoutes(routes)}
                <Route path="/" element={<Course />} />
              </Routes>
            </Box>
          ) : null}
        </Box>
      </HStack>
    </CourseContext.Provider>
  );
}
