import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useContext } from "react";
import CourseContext from "../context/CourseContext";
import { HSeparator } from "../../../../components/Separator";
import SidebarContent from "./Content";

function Sidebar() {
  let variantChange = "0.2s linear";
  // Chakra Color Mode
  let sidebarBg = useColorModeValue("white", "navy.800");
  let sidebarMargins = "0px";

  // SIDEBAR
  return (
    <Box minH="100%">
      <Box
        bg={sidebarBg}
        transition={variantChange}
        w={"300px"}
        minW={"300px"}
        h="100vh"
        m={sidebarMargins}
        overflowX="hidden"
        border={"1px solid rgba(135, 140, 189, 0.3)"}
      >
        <Flex w="full" alignItems="center" flexDirection="column" pt={"20px"}>
          <Text w="full" fontSize="2xl" fontWeight={800} color={"black"} pl="20px">
            {"Roadmap Course Tree"}
          </Text>

          <HSeparator my="20px" />
        </Flex>
        <SidebarContent />
      </Box>
    </Box>
  );
}

export default Sidebar;
