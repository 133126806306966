import { isEmpty } from "lodash";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ReloadState from "../../components/ReloadState";
import { useAppSelector } from "../../hooks/useAppSelector";
import { selectUser } from "../../redux/slice";
import { USER_ROLE } from "../../utils/constants";
import Layout from "./layout/Layout";

export const CourseManagement = () => {
  const location = useLocation();
  const user = useAppSelector(selectUser);
  const navigate = useNavigate();

  React.useEffect(() => {
    async function checkAuthorization() {
      if (isEmpty(user) && location.pathname.indexOf("/course-management") !== -1) {
        return navigate("/login");
      }

      if (
        location.pathname.includes("/course-management") &&
        ![USER_ROLE.ADMIN, USER_ROLE.COLLABORATOR].includes(user.roleId)
      ) {
        return navigate("/");
      }
    }
    checkAuthorization();
  }, [user, location.pathname]);

  return (
    <>
      <ReloadState />
      <Layout />
    </>
  );
};
