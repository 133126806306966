import { DragHandleIcon } from "@chakra-ui/icons";
import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { MdOutlineEdit } from "react-icons/md";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import roadmapService from "../../../../services/roadmapService";
import { SortableItem } from "../../../../components/SortableItem";

function ListQuestion({ questionList, setQuestionList }: { questionList: any[]; setQuestionList: any }) {
  const navigate = useNavigate();
  const { id, levelId, nodeId, lessonId } = useParams();
  const [queries] = useSearchParams();
  const type = queries.get("type");

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10
      }
    })
  );

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = questionList.findIndex((choice) => choice.idx === active.id);
      const newIndex = questionList.findIndex((choice) => choice.idx === over.id);

      const updatedChoices = arrayMove(questionList, oldIndex, newIndex).map((choice, index) => ({
        ...choice,
        idx: index + 1
      }));

      try {
        const { data: { data: _updatedData } = { data: {} } } = await roadmapService.arrangeQuestion(
          nodeId,
          updatedChoices.map((choice) => {
            return { id: choice.id, idx: choice.idx };
          })
        );

        setQuestionList((_prev: any) => updatedChoices);
      } catch (_error) {}
    }
  };
  
  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={questionList.map((choice) => choice.idx!)} strategy={verticalListSortingStrategy}>
          {questionList.map((choice) => (
            <SortableItem key={choice.idx} id={choice.idx!}>
              {(attributes, listeners) => (
                <Box
                  w="full"
                  display="flex"
                  alignItems="center"
                  gap={4}
                  userSelect={"none"}
                  bg={"white"}
                  border={"1px solid rgba(135, 140, 189, 0.3)"}
                  rounded={"10px"}
                  p={"10px"}
                  // onClick={() => {
                  //   setNode(choice);
                  //   navigate(`/course-management/${course.id}/levels/${level.id}/nodes/${choice.id}?type=${type}`);
                  // }}
                  {...attributes} // Ensure attributes are applied to Box
                  onPointerDown={(e) => e.stopPropagation()}
                >
                  <DragHandleIcon cursor={"grab"} {...listeners} />

                  <HStack w="full" spacing={4}>
                    <Text
                      minW="max-content"
                      border={"0.5px #D0D5DD solid"}
                      fontSize={"xs"}
                      fontWeight={600}
                      px="10px"
                      py="2px"
                      rounded={"6px"}
                    >
                      {choice.type}
                    </Text>
                    <Text w="full">{choice.title}</Text>
                    <HStack spacing={2}>
                      {(choice.nut ?? 0) > 0 && (
                        <Text
                          minW="max-content"
                          border={"0.5px #D0D5DD solid"}
                          boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.09)"}
                          color="brand.200"
                          fontSize={"xs"}
                          fontWeight={600}
                          px="10px"
                          py="2px"
                          rounded={"6px"}
                        >
                          {`${choice.nut} Nuts`}
                        </Text>
                      )}
                      {(choice.exp ?? 0) > 0 && (
                        <Text
                          minW="max-content"
                          border={"0.5px #D0D5DD solid"}
                          boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.09)"}
                          color="brand.200"
                          fontSize={"xs"}
                          fontWeight={600}
                          px="10px"
                          py="2px"
                          rounded={"6px"}
                        >
                          {`${choice.exp} XP`}
                        </Text>
                      )}
                    </HStack>
                  </HStack>
                  <Icon
                    as={MdOutlineEdit}
                    cursor={"pointer"}
                    onClick={() =>
                      navigate(
                        `/course-management/${id}/levels/${levelId}/nodes/${nodeId}${lessonId ? `/lessons/${lessonId}` : ""}/questions/${choice.id}?type=${type}`
                      )
                    }
                  />
                </Box>
              )}
            </SortableItem>
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
}

export default ListQuestion;
