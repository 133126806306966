import Course from "./pages/Course";
import Level from "./pages/Level";
import Node from "./pages/Node";
import Question from "./pages/Question";

const routes: RoutesType[] = [
  {
    name: "Quản lý khóa học",
    path: ":id",
    regex: /^\/course-management\/[A-Z0-9]+$/,
    resource: "course-management",
    component: <Course />,
    level: 0,
    sidebar: false
  },
  {
    name: "Cấp độ chương trình",
    path: ":id/levels/:levelId",
    regex: /^\/course-management\/[A-Z0-9]+\/levels\/[A-Z0-9]+$/,
    resource: "course-levels",
    component: <Level />,
    level: 0,
    sidebar: false
  },
  {
    name: "Roadmap Node",
    path: ":id/levels/:levelId/nodes/:nodeId",
    regex: /^\/course-management\/[A-Z0-9]+\/levels\/[A-Z0-9]+\/nodes\/[A-Z0-9]+$/,
    resource: "roadmap-nodes",
    component: <Node />,
    level: 0,
    sidebar: false
  },
  {
    name: "Roadmap Lesson",
    path: ":id/levels/:levelId/nodes/:nodeId/lessons/:lessonId",
    regex: /^\/course-management\/[A-Z0-9]+\/levels\/[A-Z0-9]+\/nodes\/[A-Z0-9]+\/lessons\/[A-Z0-9]+$/,
    resource: "roadmap-lessons",
    component: <Node />,
    level: 0,
    sidebar: false
  },
  {
    name: "Roadmap Question",
    path: ":id/levels/:levelId/nodes/:nodeId/lessons/:lessonId/questions/:questionId",
    regex:
      /^\/course-management\/[A-Z0-9]+\/levels\/[A-Z0-9]+\/nodes\/[A-Z0-9]+\/lessons\/[A-Z0-9]+\/questions\/[A-Z0-9]+$/,
    resource: "roadmap-questions",
    component: <Question />,
    level: 0,
    sidebar: false
  },
  {
    name: "Roadmap Question",
    path: ":id/levels/:levelId/nodes/:nodeId/questions/:questionId",
    regex: /^\/course-management\/[A-Z0-9]+\/levels\/[A-Z0-9]+\/nodes\/[A-Z0-9]+\/questions\/[A-Z0-9]+$/,
    resource: "roadmap-questions",
    component: <Question />,
    level: 0,
    sidebar: false
  }
];

export default routes;
