import {
  Button,
  Checkbox,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Radio,
  Text,
  VStack
} from "@chakra-ui/react";
import { set } from "lodash";
import { useEffect, useState } from "react";

export default function EditTypeModal({
  type,
  onUpdate,
  onClose
}: {
  type: string;
  onUpdate: (data: any) => void;
  onClose: () => void;
}) {
  const types = [
    {
      label: "Hot spot",
      value: "hot-spot"
    },
    {
      label: "Snippet",
      value: "snippet"
    },
    {
      label: "Multi choices",
      value: "multi-choices"
    },
    {
      label: "Single choice",
      value: "single-choice"
    },
    {
      label: "Drag & Drop",
      value: "drag-drop"
    }
  ];
  const [selectedOption, setSelectedOption] = useState<any>();

  useEffect(() => {
    setSelectedOption(type);
  }, [type]);

  const handleSelectOption = (option: string) => {
    setSelectedOption(option);
  };

  const handleSave = async () => {
    onUpdate(selectedOption);
  };

  return (
    <Portal>
      <Modal isCentered={true} isOpen={true} onClose={onClose} size={{ base: "sm", md: "md" }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalBody>
            <VStack spacing={4} align="flex-start">
              {types.map((item: any, index: number) => (
                <HStack
                  key={index}
                  w="full"
                  spacing={"8px"}
                  cursor="pointer"
                  py={2}
                  px={4}
                  color={"secondaryGray.900"}
                  _hover={{
                    bg: "brand.500",
                    color: "white"
                  }}
                  onClick={() => handleSelectOption(item.value)}
                >
                  <Radio isChecked={item.value === selectedOption} />
                  <Text w="full">{item.label}</Text>
                </HStack>
              ))}
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              cursor={"pointer"}
              bg="brand.500"
              color="white"
              _hover={{
                bgColor: "brand.600",
                color: "white"
              }}
              onClick={handleSave}
            >
              {"Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Portal>
  );
}
